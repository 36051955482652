import Image from "next/image";
import React from "react";

import { CircularProgress, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import ActivityCard from "@/common/components/ActivityCard";
import NothingToShow from "@/common/components/nothing-to-show/NothingToShow";
import SkeletonContentCard from "@/common/components/SkeletonContentCard";
import SkeletonPostCard from "@/common/components/SkeletonPostCard";
import SuggestedActivitySection from "@/community/components/SuggestedActivitySection";
import { People } from "@/discover/components/People/People";
import LiveStreamCard from "@/liveStreams/components/LiveStreamCard/LiveStreamCard";
import PostCard from "@/posts/components/PostCard";

import CommunityIllustration from "@/common/images/community-illustration.svg";
import { ExtendedActivityType } from "@/graphql/types";

const useStyles = makeStyles()((theme: Theme) => ({
  list: {
    listStyle: "none",
    margin: 0,
    padding: 0,
    "& li": {
      marginBottom: "1rem",
    },

    [theme.breakpoints.down("sm")]: {
      "& div.Postcard": {
        borderRadius: "0 !important",
      },
    },
  },
  headingFilter: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: "1.2rem",
  },
  itemWrapper: {
    width: "100%",
  },
  infiniteScroll: {
    overflow: "initial",
    margin: "-15px",
    padding: 15,
  },
}));

type TProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  loading: boolean;
  hasMorePages: boolean;
  fetchNextItemsPage: any;
};
const Timeline = ({ items, loading, hasMorePages, fetchNextItemsPage }: TProps) => {
  const { cx, classes } = useStyles();
  const { t } = useTranslation();

  const isViewportMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const renderCard = (item: any) => {
    switch (item.__typename) {
      case "CommunityPost":
        return (
          <PostCard
            post={item.post}
            inCommunityFeed
            showAdditionalInfo
            postActivity={item.postActivity}
          />
        );
      case "Post":
        return <PostCard post={item} />;
      case "LiveStream":
        return <LiveStreamCard inCommunityFeed liveStream={item} showFollowButton={true} />;
      default:
        return <ActivityCard showAuthor activity={item} hideHeaderIcon cardType="vertical" />;
    }
  };

  const renderSkeleton = () =>
    new Array(4).fill(null).map((_, index) => {
      return (
        <li key={index}>
          <SkeletonPostCard />
          <SkeletonContentCard variant="vertical" />
        </li>
      );
    });

  const renderTimeline = () => {
    return isViewportMd ? (
      <>
        {items.slice(0, 3).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
        <SuggestedActivitySection
          activityType={ExtendedActivityType.Group}
          title={t("Home.Sidebar.SuggestedGroups")}
          numberOfActivities={2}
        />
        {items.slice(3, 6).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
        <SuggestedActivitySection
          activityType={ExtendedActivityType.Debate}
          title={t("Home.Sidebar.SuggestedDebates")}
          numberOfActivities={2}
        />
        {items.slice(6, 9).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
        <SuggestedActivitySection
          activityType={ExtendedActivityType.Event}
          title={t("Home.Sidebar.UpcomingEvents")}
          numberOfActivities={2}
        />
        {items.slice(9, 12).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
        <SuggestedActivitySection
          activityType={ExtendedActivityType.Blog}
          title={t("Home.Sidebar.SuggestedBlogs")}
          numberOfActivities={2}
        />
        {items.slice(12, 15).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
        <SuggestedActivitySection
          activityType={ExtendedActivityType.Petition}
          title={t("Home.Sidebar.SuggestedPetitions")}
          numberOfActivities={2}
        />
        {items.slice(15).map((item, index) => (
          <li key={`item_${index}`}>{renderCard(item)}</li>
        ))}
      </>
    ) : (
      items.map((item, index) => <li key={`item_${index}`}>{renderCard(item)}</li>)
    );
  };

  const nothingToShowLinks = [{ url: "/discover-people", text: t("Community.FindPeople") }];

  return (
    <>
      <People />
      {items.length === 0 && !loading ? (
        <NothingToShow
          subtitle={t("Community.NothingToShowSubtitle")}
          footerElements={nothingToShowLinks}
          image={<Image src={CommunityIllustration} alt="Community" />}
        />
      ) : (
        <InfiniteScroll
          className={cx(classes.infiniteScroll, "infiniteScrollTimeline")}
          dataLength={items.length}
          next={fetchNextItemsPage}
          hasMore={hasMorePages}
          loader={
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} />
            </div>
          }
          endMessage={null}>
          <ol className={classes.list}>{loading ? renderSkeleton() : renderTimeline()}</ol>
        </InfiniteScroll>
      )}
    </>
  );
};

export default Timeline;
