import React, { ReactNode } from "react";

import { Box, Theme, Typography } from "@mui/material";
import { withStyles } from "tss-react/mui";

const styles = (theme: Theme) =>
  ({
    box: {
      margin: theme.spacing(1.5, 0, 0, 0),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    text: {
      fontWeight: 800,
      color: theme.mode.text.heading,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3125rem",
        paddingLeft: theme.spacing(0),
      },
    },
  } as const);

const Heading = (props: { classes?: { box?: string; text?: string }; children: ReactNode }) => {
  const { classes } = props;
  return (
    <Box className={classes?.box}>
      <Typography variant="h3" color="textSecondary" className={classes?.text}>
        {props.children}
      </Typography>
    </Box>
  );
};

export default withStyles(Heading, styles);
