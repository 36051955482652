import React from "react";

import { useTranslation } from "react-i18next";

import SearchIcon from "@/common/icons/SearchIcon";

import { ExtendedActivityType } from "@/graphql/types";

import { Button } from "../button/Button";
import { useSuggestedActivitiesStyles } from "./SuggestedActivities.styles";

type TProps = {
  selectedTypeOption: ExtendedActivityType | "all";
  onEditInterests: () => void;
};
function EmptyState({ selectedTypeOption, onEditInterests }: TProps) {
  const { classes } = useSuggestedActivitiesStyles();
  const { t } = useTranslation();
  const getActivityTranslation = () => {
    switch (selectedTypeOption) {
      case ExtendedActivityType.Group:
        return "Group";
        break;
      case ExtendedActivityType.Debate:
        return "Debate";
        break;
      case ExtendedActivityType.Event:
        return "Event";
        break;
      case ExtendedActivityType.Blog:
        return "Blog";
        break;
      case ExtendedActivityType.Petition:
        return "Petition";
        break;
      case ExtendedActivityType.LiveStream:
        return "Livestream";
        break;

      default:
        break;
    }
  };
  const getActivityLink = () => {
    switch (selectedTypeOption) {
      case ExtendedActivityType.Group:
        return "/groups/create/";
        break;
      case ExtendedActivityType.Debate:
        return "/debates/create/";
        break;
      case ExtendedActivityType.Event:
        return "/events/create/";
        break;
      case ExtendedActivityType.Blog:
        return "/blogs/create/";
        break;
      case ExtendedActivityType.Petition:
        return "/petitions/create/";
        break;
      case ExtendedActivityType.LiveStream:
        return "/livestreams/create/";
        break;

      default:
        break;
    }
  };
  return (
    <div className={classes.emptyContainer}>
      <p>
        <SearchIcon />
      </p>
      <p className="title">{t("Common.NothingToShowTitle")}</p>
      <p className="subtitle">{t("App.Activities.Suggested.EmptySubtitle")}</p>
      <div className={classes.editInterests}>
        <Button onClick={onEditInterests} className={classes.editInterestsButton}>
          {t("Generic.EditInterests")}
        </Button>
      </div>
      {selectedTypeOption !== "all" && (
        <>
          <div className={classes.separator}>{t("Generic.or")}</div>
          <div className={classes.create}>
            <Button href={getActivityLink()} variant="text" className={classes.createButton}>
              {t(`Common.EditInterests.Create${getActivityTranslation()}`)}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default EmptyState;
