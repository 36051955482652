import React from "react";

import { useTranslation } from "react-i18next";

import { AppTabs } from "@/common/components/Tabs";
import TourStep from "@/tour/components/TourStep";

export enum HomeTab {
  Discover = "Discover",
  Timeline = "Timeline",
  News = "News",
}

export const HomeTabs = (props: any) => {
  const { t } = useTranslation();
  return (
    <AppTabs {...props}>
      {[
        {
          tab: HomeTab.Timeline,
          link: "/",
          label: "Timeline", //TODO TRANSLATE
          enabled: true,
        },
        {
          tab: HomeTab.Discover,
          link: "/discover",
          label: t("Home.Discover"),
          enabled: true,
        },
        {
          tab: HomeTab.News,
          link: "/news",
          label: (
            <TourStep
              topic="discover"
              step={3}
              render={(props) => <span {...props}>{t("Home.News")}</span>}
            />
          ),
          enabled: false,
        },
      ]}
    </AppTabs>
  );
};
