import Head from "next/head";
import { useRouter } from "next/router";
import React, { HTMLProps, ReactNode } from "react";

import { useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";

import { getAlternateUrl } from "@/common/services/locale";

import { SITE_URL } from "../constants";

type MetaTag = HTMLProps<HTMLMetaElement>;

type SEOProps = {
  title?: string;
  titleTemplate?: string;

  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  twitterImage?: string;

  customMetaTags?: MetaTag[];
  disableIndexing?: boolean;
};

const addMetaTagIfNotSet = (metaTags?: MetaTag[]) =>
  Object.assign(
    (metaTag: MetaTag) =>
      !metaTags?.some((m) => m.name === metaTag.name) ? <meta {...metaTag} /> : null,
    { displayName: "MetaTag" },
  );

const SEO = (props: SEOProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultSeo = {
    titleTemplate: t("SEO.TitleTemplates.Default", { lng: router.locale }),
    title: t("SEO.Titles.Default", { lng: router.locale }),
    description: t("SEO.Descriptions.Default", { lng: router.locale }),
    ogImage: "/images/og-image.png",
    twitterImage: "/images/twitter-image.png",
  };

  const titleTemplate = props.titleTemplate || defaultSeo.titleTemplate;
  const pageTitle = titleTemplate.replace("{title}", props.title || defaultSeo.title);
  const ogTitle = props.ogTitle || props.title || pageTitle || defaultSeo.title;
  const description = props.ogDescription || defaultSeo.description;

  const addIfNotSet = addMetaTagIfNotSet(props.customMetaTags);

  const ogImage = props.ogImage || props.twitterImage || SITE_URL + defaultSeo.ogImage;
  const twitterImage = props.twitterImage || props.ogImage || SITE_URL + defaultSeo.twitterImage;

  const subdomain = SITE_URL.split("//")[1].split(".")[0];
  const noIndex =
    subdomain === "development" || subdomain === "acceptance" || props.disableIndexing;

  const getFullURL = (includeQueryString: boolean) => {
    const url = SITE_URL + (router.locale === "default" ? "" : "/" + router.locale) + router.asPath;
    return includeQueryString ? url : url.split("?")[0];
  };

  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>

      <meta name="title" content={ogTitle} />
      <meta name="description" content={description} />

      {addIfNotSet({ property: "og:type", content: "website" })}
      {addIfNotSet({ property: "og:url", content: getFullURL(true) })}

      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />

      {addIfNotSet({ name: "twitter:card", content: "summary_large_image" })}
      {addIfNotSet({
        name: "twitter:url",
        content: getFullURL(true),
      })}

      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={twitterImage} />

      <meta property="og:site_name" content="PoliticAll" />
      <meta name="theme-color" content={theme.mode.background.light} />

      <meta
        name="viewport"
        content="width=device-width,height=device-height,initial-scale=1.0,user-scalable=no,user-scalable=0"
      />
      <link rel="canonical" href={getFullURL(false)} />
      {router.locales?.reduce((acc, locale) => {
        if (locale !== "default") {
          acc.push(
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale === "default" ? undefined : locale}
              href={getAlternateUrl(router.asPath, locale)}
            />,
          );
        }

        return acc;
      }, [] as ReactNode[])}
      {props.customMetaTags?.map((metaTag, i) => (
        <meta key={i} {...metaTag} />
      ))}

      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default SEO;
