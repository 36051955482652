import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme: Theme) =>
    ({
      root: {
        width: `calc(100% - ${theme.spacing(4)})`,
        marginLeft: 0,
        "& > div:last-child": {
          paddingRight: "26px",
        },
      },
      personDetails: {
        display: "inline",
        fontSize: 12,
        color: "#686868",
      },
      personName: {
        fontWeight: "bold",
        color: theme.palette.common.black,
        fontSize: 14,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
      },
      discoverMorePeople: {
        textAlign: "center",
        fontSize: 16,
        color: "#a573ff",
      },
      follow: {
        color: theme.palette.primary.main,
      },
      listGutters: {
        paddingLeft: 0,
        paddingRight: 30,
      },
      inset: {
        marginLeft: 56,
      },
      btns: {
        right: 7,
      },
      card: {
        textAlign: "center",
      },
      avatar: {
        display: "inline-flex",
        width: 66,
        height: 66,
        cursor: "pointer",
      },
      personCard: {
        width: 160,
        height: 508,
        borderRadius: 16,
        boxShadow: "0 0px 20px -8px rgb(190 190 197)",
        marginRight: 5,
      },
      primaryText: {
        fontWeight: 800,
        color: "#3f3d56",
        marginTop: 5,
      },
      secondaryText: {
        color: "#6c6b78",
        fontSize: 13,
      },
      followText: {
        fontWeight: 800,
        fontSize: 16,
        marginTop: 7,
        cursor: "pointer",
        display: "inline-block",
        padding: "3px 9px",
        borderRadius: "9px",
        transition: "0.2s",
        "&:hover": {
          backgroundColor: "#F7F7F7",
          color: theme.palette.primary.main,
        },
      },
      followTextSelected: {
        color: "#3f3d56",
      },
      controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      cardContent: {
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "center",
      },
    } as const),
);

export default useStyles;
