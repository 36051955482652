import React from "react";

import { Paper, Skeleton, Theme, useMediaQuery } from "@mui/material";

import useStyles from "./PersonCardSkeleton.styles";

type PersonCardSkeletonProps = {
  inPeopleGrid?: boolean;
};

export const PersonCardSkeleton = ({ inPeopleGrid }: PersonCardSkeletonProps) => {
  const { classes, cx } = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const avatarSize: number = isMobile ? 56 : 72;

  return (
    <>
      <Paper className={cx(classes.paper, { [classes.personCardGrid]: inPeopleGrid })}>
        <div className={classes.avatarContainer}>
          <Skeleton variant="circular" width={avatarSize} height={avatarSize}></Skeleton>
        </div>
        <div className={classes.rowContainer}>
          <Skeleton variant="text" width="85%" className="username" height={30}></Skeleton>
        </div>
        <div className={classes.rowContainer}>
          <Skeleton variant="text" width="85%" className="contacts" height={20}></Skeleton>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: "-2px" }}>
          <Skeleton variant="text" width="80%" className="interests" height={20}></Skeleton>
        </div>
        <div className={classes.rowContainer} style={{ marginTop: "5px" }}>
          <Skeleton variant="text" width="65%" className="cta" height={35}></Skeleton>
        </div>
      </Paper>
    </>
  );
};
