import React from "react";

import { useTheme } from "@mui/material";

function IconsChevronLeft() {
  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-1102.000000, -1254.000000) translate(314.000000, 1249.000000) translate(717.000000, 5.000000) translate(71.000000, 0.000000)"
                />
                <path
                  fill={color}
                  d="M7.21 9.044c-.563.12-1.003.466-1.154.911-.151.445.01.92.423 1.245l4.344 3.426c.31.242.726.376 1.159.374.418-.005.814-.133 1.112-.36l4.345-3.307c.44-.284.654-.773.523-1.244-.13-.47-.585-.844-1.175-.967l-.162-.027c-.541-.067-1.095.085-1.466.41l-3.173 2.41L8.79 9.376c-.413-.325-1.014-.452-1.578-.333z"
                  transform="translate(-1102.000000, -1254.000000) translate(314.000000, 1249.000000) translate(717.000000, 5.000000) translate(71.000000, 0.000000) translate(12.000000, 12.000010) rotate(-90.000000) translate(-12.000000, -12.000010)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconsChevronLeft;
