import React from "react";

import { useScrollTrigger } from "@mui/material";

function ElevationScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 3 : 0,
  });
}

export default ElevationScroll;
