import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import Carousel from "@/common/components/Carousel";
import { PersonCard } from "@/common/components/PersonCard/PersonCard";

import { useElementSize } from "@/common/hooks/use-element-size";

import { PeopleProvider } from "@/discover/services";
import { UserCardFragment, UserFragment } from "@/graphql/types";

import useStyles from "./PeopleDesktop.styles";

interface Props {
  people: UserFragment[] | UserCardFragment[];
}

const CARD_WIDTH = 181;
const DEFAULT_GROUP_SIZE = 8;

function PeopleDesktop({ people }: Props) {
  const [groupSize, setGroupSize] = useState(DEFAULT_GROUP_SIZE);
  const { classes } = useStyles();

  const {
    size: [width],
    ref: wrapperRef,
  } = useElementSize();

  const [groups, setGroups] = useState<{ people: UserFragment[] | UserCardFragment[] }[]>([]);

  const getGroupsOfPeople = (size: number) => {
    const peopleProvider = new PeopleProvider(people);

    const groupedPeople = peopleProvider.getGroupsOfPeople(size);

    if (groupedPeople.length > 0) {
      setGroups(groupedPeople);
    }
  };

  useEffect(() => {
    if (people && people.length !== 0) {
      getGroupsOfPeople(groupSize);
    }
  }, [people, groupSize]);

  const computeGroupSize = () => {
    const size = Math.floor(width / CARD_WIDTH);

    if (size > 0) {
      setGroupSize(size + 1);
    }
  };

  useEffect(() => {
    computeGroupSize();
  }, [width]);

  const onIndicatorPressed = () => {
    computeGroupSize();
  };

  function VisiblePeople({
    people: displayedPeople,
  }: {
    people: UserFragment[] | UserCardFragment[];
  }) {
    const content = displayedPeople.map((person, index) => {
      return (
        <Grid item key={person.id} className="personCard">
          <PersonCard key={index} person={person} />
        </Grid>
      );
    });

    return (
      <div className={classes.card}>
        <Grid container spacing={1} className={classes.root} wrap="nowrap">
          {content}
        </Grid>
      </div>
    );
  }

  return groups.length > 0 ? (
    <div ref={wrapperRef}>
      <Carousel
        autoPlay={false}
        animation="fade"
        navButtonsAlwaysVisible={true}
        indicators={false}
        timeout={200}
        indicatorPressed={onIndicatorPressed}>
        {groups.map((group, index) => {
          return <VisiblePeople key={index} people={group.people} />;
        })}
      </Carousel>
    </div>
  ) : null;
}

export default PeopleDesktop;
