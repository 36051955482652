import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useState } from "react";

import { Box, Theme, useMediaQuery } from "@mui/material";

import { BottomBar } from "@/common/components/BottomBar/BottomBar";
import { Sidebar } from "@/common/components/Sidebar/Sidebar";
import { SuggestedActivitiesProps } from "@/common/components/SuggestedActivities/SuggestedActivities";
import TopBar from "@/common/components/TopBar";
import NotificationsPanel from "@/notifications/components/NotificationsPanel";

import { useAuthenticationStore } from "@/authentication/hooks";
import { bindDialogState, useDialog } from "@/common/hooks";

import { AppBarActions, BottomBarActions, useAppBarActions } from "@/common/models/AppBarActions";
import { ActivityFragment } from "@/graphql/types";

const SuggestedActivities = dynamic<SuggestedActivitiesProps>(
  () =>
    import("@/common/components/SuggestedActivities/SuggestedActivities").then(
      (module) => module.SuggestedActivities,
    ),
  {
    ssr: false,
  },
);
import useStyles from "./MainLayout.styles";

export type MainLayoutProps = {
  tabs?: React.ReactNode;
  searchAlwaysOpen?: boolean;
  actionHeader?: React.ReactNode;
  inActivity?: boolean;
  children?: React.ReactNode;
  currentActivity?: ActivityFragment | null;
  isChatContainer?: boolean;
};

export const MainLayout = (props: MainLayoutProps) => {
  const { cx, classes } = useStyles();
  const dialogState = useDialog();
  const router = useRouter();
  const hideTopAndBottomBar =
    router.query?.hideTopAndBottomBar?.toString().trim().toLowerCase() === "true";
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { isUserLoggedIn } = useAuthenticationStore();

  const handleAppBarAction = useAppBarActions({
    [AppBarActions.Menu]: () => setMenuOpen(!menuOpen),
    [AppBarActions.Notifications]: () => dialogState.open(),
  });

  const handleBottomBarAction = (action: BottomBarActions) => {
    switch (action) {
      case BottomBarActions.Menu:
        setMenuOpen((flag) => !flag);
        break;
      default:
        setMenuOpen(false);
        break;
    }
  };

  return (
    <div className={classes.root}>
      {hideTopAndBottomBar || (
        <TopBar
          tabs={props.tabs}
          onActionClicked={handleAppBarAction}
          searchAlwaysOpen={props.searchAlwaysOpen}
        />
      )}

      <div
        className={cx(classes.contentContainer, { [classes.hiddenTopBar]: hideTopAndBottomBar })}>
        <Sidebar open={menuOpen} onAction={handleAppBarAction} onClose={() => setMenuOpen(false)} />

        {isMobile || <NotificationsPanel {...bindDialogState(dialogState)} />}

        {isMobile && props.tabs && (
          <Box mx={2} pt={props.tabs ? 9 : 0}>
            {props.tabs}
          </Box>
        )}

        {!props.inActivity ? (
          <div>
            {props.actionHeader}
            <Box
              className={cx(classes.mainContainer, {
                [classes.chatContainer]: props.isChatContainer,
              })}>
              <div className={classes.innerContent}>{props.children}</div>
            </Box>
          </div>
        ) : (
          <div>
            {props.actionHeader}
            <div className={classes.mainContainerWithSidebar}>
              <div className={classes.mainContainerCenter}>{props.children}</div>
              <div className={classes.mainContainerSidebar}>
                {isUserLoggedIn && !isMobile && (
                  <SuggestedActivities currentActivity={props.currentActivity} />
                )}
              </div>
            </div>
          </div>
        )}

        {isMobile && isUserLoggedIn && !hideTopAndBottomBar && (
          <BottomBar onAction={handleBottomBarAction} menuIsOpen={menuOpen} />
        )}
      </div>
    </div>
  );
};
