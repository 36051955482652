import {
  DiscoverUsersMode,
  ActivitiesOrder,
  useDiscoverActivitiesLazyQuery,
  useDiscoverPeopleLazyQuery,
  ExtendedActivityType,
  DiscoverPeopleQueryVariables,
} from "@/graphql/types";

export const DEFAULT_EXENDED_ACTIVITY_TYPES = [
  ExtendedActivityType.Group,
  ExtendedActivityType.Debate,
  ExtendedActivityType.Event,
  ExtendedActivityType.Blog,
  ExtendedActivityType.Petition,
  ExtendedActivityType.LiveStream,
];

type FetchActivitiesProps = {
  limit: number;
  types?: ExtendedActivityType[];
  sortBy?: ActivitiesOrder;
};

export const useDiscoverStore = () => {
  const [
    fetchActivities,
    {
      data: activitiesData,
      loading: fetchActivitiesLoading,
      error,
      refetch: refetchActivities,
      fetchMore: fetchNextActivitiesPage,
    },
  ] = useDiscoverActivitiesLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [
    fetchPeople,
    { data: peopleData, loading: fetchPeopleLoading, fetchMore: fetchNextPeoplePage },
  ] = useDiscoverPeopleLazyQuery({
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  return {
    get activities() {
      if (activitiesData) {
        return activitiesData.discoverActivities.edges.map(({ node }) => node);
      }
      return [];
    },

    people: peopleData?.discoverPeople?.edges?.map(({ node }) => node) || [],
    get loading() {
      return fetchActivitiesLoading || fetchPeopleLoading;
    },
    get error() {
      return error !== undefined;
    },
    get hasMorePages() {
      if (activitiesData) {
        return activitiesData.discoverActivities.pageInfo.hasNextPage;
      }
      return false;
    },
    hasMorePeoplePages: peopleData?.discoverPeople?.pageInfo.hasNextPage || false,
    fetchActivities({
      limit,
      types = DEFAULT_EXENDED_ACTIVITY_TYPES,
      sortBy,
    }: FetchActivitiesProps) {
      return fetchActivities({
        variables: {
          limit,
          types,
          sortBy,
        },
      });
    },
    refetchActivities() {
      if (refetchActivities) {
        return refetchActivities();
      }
    },
    fetchNextActivitiesPage() {
      if (fetchNextActivitiesPage && activitiesData) {
        if (!activitiesData.discoverActivities.pageInfo.endCursor) {
          return;
        }
        return fetchNextActivitiesPage({
          variables: {
            after: activitiesData.discoverActivities.pageInfo.endCursor,
          },
        });
      }
    },
    fetchPeople({ limit, mode, excludedUserId }: DiscoverPeopleQueryVariables) {
      return fetchPeople({
        variables: {
          limit,
          mode,
          excludedUserId,
        },
      });
    },
    fetchNextPeoplePage({ mode }: { mode?: DiscoverUsersMode }) {
      if (fetchNextPeoplePage && peopleData) {
        return fetchNextPeoplePage({
          variables: {
            after: peopleData.discoverPeople?.pageInfo.endCursor,
            mode,
          },
        });
      }
    },
  };
};
