import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  headingContainer: {
    [theme.breakpoints.up("xs")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  headingTitle: {
    fontWeight: "800",
    fontSize: "1.0625rem",
    [theme.breakpoints.up("xs")]: {
      flexGrow: 1,
    },
  },
  headingLinkContainer: {
    display: "flex",
    flexShrink: 0,
    marginRight: -10,
  },
  headingLink: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontSize: "0.8125rem",
    lineHeight: "1.38",
    fontWeight: 800,
  },
  gridSkeleton: {
    paddingBottom: 16,
    paddingTop: 16,
    paddingLeft: 4,
  },
  gridSkeletonItem: {
    padding: "0px !important",
  },
  mainBoxSmall: {
    margin: theme.spacing(0, -2.5),
    padding: theme.spacing(0, 2.5),
    overflow: "hidden",
    marginBottom: 6,
  },
  mainBox: {
    padding: "16px",
    overflow: "auto",
  },
  peopleTitleBox: {
    marginTop: 5,
  },
}));
