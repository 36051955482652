import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useSidebarStyles = makeStyles()((theme: Theme) => {
  return {
    sidebarSection: {
      "& section:last-child": {
        marginBottom: 0,
      },
    },
    sidebarSectionFooter: {
      display: "flex",
      justifyContent: "center",
    },
    seeMoreButton: {
      fontSize: "1.065rem",
      fontWeight: "bold",
    },
    cardWrapper: {
      width: "100%",
    },
    headingLinkContainer: {
      display: "flex",
      flexShrink: 0,
    },
    headingLink: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      fontSize: "1.1rem",
      fontWeight: 800,
      textDecoration: "none",
    },
  };
});
