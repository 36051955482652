import {
  useCloseVerificationBannerMutation,
  useGetVerificationAccessTokenQuery,
} from "@/graphql/types";

export const useUserVerificationStore = () => {
  const { loading, data, error } = useGetVerificationAccessTokenQuery();
  const [closeVerificationBanner, { data: closeBannerData, loading: loadingCloseBanner }] =
    useCloseVerificationBannerMutation();

  return {
    verificationAccessToken: data?.verificationAccessToken || "",
    closeVerificationBanner,
    loadingCloseBanner,
    loading,
    error,
  };
};
