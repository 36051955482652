import React from "react";

import { Box, BoxProps, Theme, useMediaQuery } from "@mui/material";

type MainBoxProps = BoxProps;

function MainBox(props: MainBoxProps) {
  const { children, pt, px, ...rest } = props;
  const sm = useMediaQuery((t: Theme) => t.breakpoints.down("md"));
  const xs = useMediaQuery((t: Theme) => t.breakpoints.down("sm"));
  const ptv = pt !== undefined ? pt : sm ? 2 : 3;
  const pxv = px !== undefined ? px : xs ? 2 : 0;
  return (
    <Box pt={ptv} px={pxv} {...rest}>
      {children}
    </Box>
  );
}

export default MainBox;
