import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useSuggestedActivitiesStyles = makeStyles()((_theme: Theme) => ({
  container: {
    width: "370px",
    flexShrink: "0",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "7px 0px",
  },
  title: {
    fontSize: "1.0625rem",
    fontWeight: "800",
    lineHeight: "1.29",
  },
  orderSelect: {
    "& .MuiSelect-select": {
      fontSize: "0.8125rem",
    },
  },
  infiniteScrollContainer: {
    // necessary to avoid clipping of box-shadow
    padding: "11px",
    margin: "-11px",
  },
  spinnerContainer: {
    textAlign: "center",
    paddingTop: "24px",
  },
  emptyContainer: {
    height: "100vh",
    width: "100%",
    paddingTop: "25vh",
    textAlign: "center",

    "& svg": {
      width: "32px",
      height: "32px",
      marginBottom: "8px",
    },
    "& .title": {
      fontWeight: "bold",
      fontSize: "1.0625rem",
    },
    "& .subtitle": {
      fontSize: "0.9375rem",
      width: 200,
      margin: "auto",
      marginTop: "8px",
    },
  },
  editInterests: {
    marginTop: "24px",
  },
  editInterestsButton: {
    paddingLeft: "40px",
    paddingRight: "40px",
    fontSize: "0.9375rem",
    fontWeight: "800",
    textTransform: "uppercase",
    margin: "auto",
  },
  separator: {
    marginTop: "12px",
    fontSize: "0.9375rem",
    lineHeight: "1.47",
  },
  create: {
    marginTop: "12px",
    display: "flex",
    justifyContent: "center",
  },
  createButton: {
    fontSize: "0.9375rem",
    fontWeight: "800",
    lineHeight: "1.47",
    padding: 0,
    minHeight: 0,
    textTransform: "uppercase",
  },
}));
