import { useRouter } from "next/router";
import React, { useEffect, useState, ReactNode } from "react";

import { Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  flexContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-evenly",
    },
  },
  indicator: {
    height: 0,
  },
  smallFont: {
    ".MuiTab-root": {
      [theme.breakpoints.down(1250)]: {
        fontSize: "1.065rem",
        padding: "12px 14px",
      },
    },
  },
  tabRoot: {
    minWidth: 0,
    fontWeight: "bold",
    color: "#bebec5",
    transition: "0.2s",
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
      borderRadius: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      borderRadius: theme.spacing(1),
      color: "#6c6b78",
      marginTop: theme.spacing(3),
    },

    "&:hover": {
      backgroundColor: theme.mode.background.default,
      color: theme.mode.text.description,
    },
  },
  tabSelected: {
    color: theme.palette.primary.main,

    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)",
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.mode.background.light + " !important",
    },
  },
}));

export interface AppTab {
  tab: string;
  label: ReactNode;
  link?: string;
  enabled: boolean;
  props?: { [key: string]: any };
}

interface AppTabsProps {
  onTabChanged?: (tab: AppTab) => void;
  activityTabs?: boolean;
  children: AppTab[];
}

export const AppTabs = (props: AppTabsProps) => {
  const { cx, classes } = useStyles();
  const routes = props.children;
  const router = useRouter();
  const [selected, setSelected] = useState(routes[0].tab);

  useEffect(() => {
    routes.forEach(({ tab, link }) => {
      if (router.pathname === link) {
        setSelected(tab);
      }
    });
  }, [routes, router.pathname]);

  const handleChange = (event: any, value: any) => {
    routes.forEach(({ tab, link }) => {
      if (tab === value && link) {
        router.replace(link);
      }
    });
  };

  return (
    <Tabs
      classes={{
        flexContainer: classes.flexContainer,
        indicator: classes.indicator,
      }}
      className={cx({ [classes.smallFont]: props.activityTabs })}
      value={selected}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}>
      {routes.map((route) => {
        if (!route.enabled) return;
        return (
          <Tab
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            key={route.tab}
            label={route.label}
            value={route.tab}
            {...(route.props || {})}
          />
        );
      })}
    </Tabs>
  );
};
