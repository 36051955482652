import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useActionHeaderStyles = makeStyles()((theme: Theme) => ({
  container: {
    background: theme.mode.background.light,
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 15,
    position: "static",
    top: "80px",
    width: "calc(100% - 287px)",
    marginLeft: "287px",
    borderTop: "4px solid" + theme.mode.background.default,
    transition: "all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    [theme.breakpoints.down("md")]: {
      "&.down": {
        position: "fixed",
        top: "77px",
      },
      top: 77,
      width: "100%",
      margin: "0",
    },
    '&[elevation="3"]': {
      boxShadow: "0 4px 16px 0 rgba(63, 61, 86, 0.06)",
    },
    "&.down": {
      visibility: "hidden",
      top: "-6rem",
    },
    "&.up": {
      boxShadow: "0 4px 16px 0 rgba(63, 61, 86, 0.06)",
      position: "fixed",
      visibility: "visible",
    },
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 800,
    color: theme.mode.text.heading,

    [theme.breakpoints.down("md")]: {
      fontSize: "1.3125rem",
    },
  },
  action: {
    marginRight: "3px",
    display: "flex",
    gap: "12px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      gap: 0,
    },
  },
  sortButton: {
    "& svg path": {
      fill: "#bebec5",
    },
    "& svg:hover": {
      background: theme.mode.icon.backgroundSecondary,
      borderRadius: "10px",
      "& path": {
        fill: "#7d7d8c",
      },
    },
  },
  activeMenuItem: {
    color: theme.palette.primary.main,
  },
  filterLeft: {
    width: "100%",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
    gap: 16,
    [theme.breakpoints.down("md")]: {
      "&.grabbing": {
        cursor: "grabbing",
      },
    },

    "& .button": {
      backgroundColor: theme.mode.icon.backgroundSecondary,
      padding: "9px 0px",
      borderRadius: "8px",
      minWidth: "130px",
      textAlign: "center",
      cursor: "pointer",
      fontSize: "0.9375rem",
      fontWeight: "600",
      lineHeight: 1.6,
      transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      userSelect: "none",

      [theme.breakpoints.down("md")]: {
        "&.grabbing": {
          cursor: "grabbing",
        },
      },
      "&.active": {
        backgroundColor: theme.mode.text.formLabel,
        color: theme.mode.text.contrast,
        "&:hover": {
          backgroundColor: theme.mode.text.formLabel,
        },
      },
      "&:hover": {
        backgroundColor: darken(theme.mode.icon.backgroundSecondary, 0.1),
      },
    },
  },
  filterRight: {
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "35px",
    },
  },
}));
