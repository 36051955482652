import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { useActionHeaderStyles } from "@/common/components/action-header/ActionHeader.styles";
import ElevationScroll from "@/common/components/ElevationScroll";

import { useScrollDirection } from "@/common/hooks/use-scroll-direction";

import { ActivitiesOrder, CommunityTimelineItemType } from "@/graphql/types";

type ActivityFilterHeaderProps = {
  onCommunityTimelineFilterChange?: (communityItemTime: CommunityTimelineItemType | null) => void;
  onOrderFilterChange?: (order: ActivitiesOrder) => void;
};

const ActivityFilterHeader = ({ onCommunityTimelineFilterChange }: ActivityFilterHeaderProps) => {
  const [activeFilter, setActiveFilter] = useState<CommunityTimelineItemType | null>(null);
  const { cx, classes } = useActionHeaderStyles();
  const { t } = useTranslation();
  const { scrollDirection, isAtTheTop } = useScrollDirection();
  const [isMouseDownIcon, setIsMouseDownIcon] = useState<boolean>(false);
  let isMouseDown = false;
  let startX = 0;
  let scrollLeft = 0;
  const slider = useRef<HTMLDivElement>(null);

  const startDragging = (e: MouseEvent) => {
    if (!slider.current) return;

    isMouseDown = true;
    startX = e.pageX - slider.current.offsetLeft;
    scrollLeft = slider.current.scrollLeft;
  };

  const stopDragging = () => {
    setIsMouseDownIcon(false);

    isMouseDown = false;
  };

  const move = (e: MouseEvent) => {
    e.preventDefault();

    if (!slider.current) return;
    if (!isMouseDown) {
      return;
    }
    setIsMouseDownIcon(true);
    const x = e.pageX - slider.current.offsetLeft;

    const scroll = x - startX;
    slider.current.scrollLeft = scrollLeft - scroll;
  };

  const handleCommunityFilterButton = (type: CommunityTimelineItemType | null) => {
    setActiveFilter(type);
    onCommunityTimelineFilterChange && onCommunityTimelineFilterChange(type);
  };

  useEffect(() => {
    if (!slider.current) return;
    slider.current?.addEventListener("mousemove", move, false);
    slider.current?.addEventListener("mousedown", startDragging, false);
    slider.current?.addEventListener("mouseup", stopDragging, false);
    slider.current?.addEventListener("mouseleave", stopDragging, false);

    return () => {
      slider.current?.removeEventListener("mousemove", move);
      slider.current?.removeEventListener("mousedown", startDragging);
      slider.current?.removeEventListener("mouseup", stopDragging);
      slider.current?.removeEventListener("mouseleave", stopDragging);
    };
  }, []);

  return (
    <ElevationScroll>
      <div
        className={cx(classes.container, {
          ["down"]: !isAtTheTop && scrollDirection === "down",
          ["up"]: !isAtTheTop && scrollDirection === "up",
        })}>
        <div
          className={cx(classes.filterLeft, {
            ["grabbing"]: isMouseDownIcon,
          })}
          ref={slider}>
          <div
            className={cx("button", { ["active"]: !activeFilter })}
            onClick={() => handleCommunityFilterButton(null)}>
            {t("Discover.AllContent")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Group,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Group)}>
            {t("Discover.Groups")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Debate,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Debate)}>
            {t("Discover.Debates")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Event,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Event)}>
            {t("Discover.Events")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Blog,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Blog)}>
            {t("Discover.Blogs")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Petition,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Petition)}>
            {t("Discover.Petitions")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.LiveStream,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.LiveStream)}>
            {t("Discover.LiveStreams")}
          </div>
          <div
            className={cx("button", {
              ["active"]: activeFilter === CommunityTimelineItemType.Post,
              ["grabbing"]: isMouseDownIcon,
            })}
            onClick={() => handleCommunityFilterButton(CommunityTimelineItemType.Post)}>
            {t("Community.TimelineFilterOptions.POST")}
          </div>
        </div>
        {/* <div className={classes.filterRight}>right</div> */}
      </div>
    </ElevationScroll>
  );
};

export default ActivityFilterHeader;
