import React, { useEffect, useState } from "react";

import { Grid, Skeleton } from "@mui/material";

import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import ActivityCard from "@/common/components/ActivityCard";
import Select from "@/common/components/Select";
import SkeletonContentCard from "@/common/components/SkeletonContentCard";
import Spinner from "@/common/components/Spinner";
import InterestsEditPopup from "@/profile/components/InterestsEditPopup";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useInterests } from "@/common/hooks/use-interests";
import { useDiscoverStore } from "@/discover/hooks";

import { ActivityFragment, ExtendedActivityType, Tag } from "@/graphql/types";

import EmptyState from "./EmptyState";
import { useSuggestedActivitiesStyles } from "./SuggestedActivities.styles";
export type SuggestedActivitiesProps = {
  currentActivity?: ActivityFragment | null;
};

export const SuggestedActivities = ({ currentActivity }: SuggestedActivitiesProps) => {
  const { classes } = useSuggestedActivitiesStyles();
  const { t } = useTranslation();
  const { session } = useAuthenticationStore();
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { availableGeoInterests, getGeoInterests } = useInterests();

  const { loading, hasMorePages, activities, fetchActivities, fetchNextActivitiesPage } =
    useDiscoverStore();

  const sortOptions = [
    { id: "all", name: t("Discover.AllContent") },
    { id: ExtendedActivityType.Group, name: t("Discover.Groups") },
    { id: ExtendedActivityType.Debate, name: t("Discover.Debates") },
    { id: ExtendedActivityType.Event, name: t("Discover.Events") },
    { id: ExtendedActivityType.Blog, name: t("Discover.Blogs") },
    { id: ExtendedActivityType.Petition, name: t("Discover.Petitions") },
    { id: ExtendedActivityType.LiveStream, name: t("Discover.LiveStreams") },
  ];
  const [selectedOrderId, setSelectedOrderId] = useState<ExtendedActivityType | "all">("all");

  const onSelectValueChange = (value: any) => {
    setSelectedOrderId(value);
  };
  const onEditInterestsHandler = () => {
    getGeoInterests({
      variables: {
        geographicalInterests:
          session?.user?.geographicalInterests.map((value) => value.toUpperCase()) || [],
      },
    });
    setPopupOpen(true);
  };

  useEffect(() => {
    fetchActivities({
      limit: 7,
      types: selectedOrderId === "all" ? undefined : [selectedOrderId],
    });
  }, [selectedOrderId]);

  useEffect(() => {
    if (activities.length > 0) {
      setIsLoading(false);
    }
  }, [activities]);

  return (
    <>
      <InterestsEditPopup
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
          fetchActivities({
            limit: 7,
            types: selectedOrderId === "all" ? undefined : [selectedOrderId],
          });
        }}
        tags={availableGeoInterests}
        selectedTags={(session?.user?.userInterests as Tag[]) || []}
      />
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="140px" height={35}></Skeleton>
            ) : (
              t("Discover.SidebarRelatedActivities")
            )}
          </div>
          <div className={classes.orderSelect}>
            {isLoading ? (
              <Skeleton variant="text" animation="wave" width="110px" height={25}></Skeleton>
            ) : (
              <Select
                value={selectedOrderId}
                options={sortOptions}
                onChange={(event) => onSelectValueChange(event.target.value as string)}
              />
            )}
          </div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={activities.length}
            next={fetchNextActivitiesPage}
            hasMore={hasMorePages && activities.length < 18}
            loader={
              <div className={classes.spinnerContainer}>
                <Spinner />
              </div>
            }
            endMessage={null}
            className={classes.infiniteScrollContainer}>
            <Grid item container spacing={2} data-cy="Related__Activities">
              {activities.length === 0 && !loading && (
                <EmptyState
                  selectedTypeOption={selectedOrderId}
                  onEditInterests={onEditInterestsHandler}
                />
              )}
              {loading
                ? new Array(6).fill({}).map((_, i) => (
                    <Grid item xs={12} key={i}>
                      <SkeletonContentCard variant="vertical" />
                    </Grid>
                  ))
                : activities
                    .filter((activity) => {
                      if (currentActivity) {
                        return currentActivity.id !== activity.id;
                      } else {
                        return true;
                      }
                    })
                    .map((e, i) => (
                      <Grid item xs={12} key={i}>
                        <ActivityCard activity={e} hideHeader cardType="vertical" />
                      </Grid>
                    ))}
            </Grid>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};
