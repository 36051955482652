import { useCallback, useLayoutEffect, useRef, useState } from "react";

export function useElementSize() {
  const [size, setSize] = useState<[number, number]>([0, 0]);
  const elementRef = useRef<Pick<HTMLElement, "getBoundingClientRect"> | null>(null);

  const ref = useCallback((element: Pick<HTMLElement, "getBoundingClientRect"> | null) => {
    if (element) {
      const rect = element.getBoundingClientRect();
      setSize([rect.width, rect.height]);
    }

    elementRef.current = element;
  }, []);

  useLayoutEffect(() => {
    function handleResize() {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        setSize([rect.width, rect.height]);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { size, ref };
}
