import dynamic from "next/dynamic";
import React from "react";

import HomePage from "@/home/components/HomePage";

import { useAuthenticationStore } from "@/authentication/hooks";
//
// const HomePage = dynamic(() => import("@/home/components/HomePage"));
// const AnonymousHomePage = dynamic(() => import("@/home/components/AnonymousHomePage"));
const LandingPage = dynamic(() => import("@/landing-page/LandingPage"));

import { withServerSideSessionRedirects } from "@/authentication/services";
import { InferPagePropsType } from "@/common/services/nextUtils";
import { UserLocationDocument, UserLocationQuery } from "@/graphql/types";

const Home = (props: InferPagePropsType<typeof getServerSideProps>) => {
  const { isUserLoggedIn } = useAuthenticationStore(props.session || undefined);

  if (isUserLoggedIn) {
    return <HomePage />;
  }

  return <LandingPage ipLocation={props.ipLocation} />;
};

export const getServerSideProps = withServerSideSessionRedirects(
  async (ctx) => {
    const { apolloClient } = ctx;
    var loc = "";

    try {
      const userLocation = await apolloClient.query<UserLocationQuery>({
        query: UserLocationDocument,
        context: {
          headers: {
            "x-forced-ip": ctx.req.headers["x-forwarded-for"] || ctx.req.headers["x-real-ip"] || "",
            "x-real-ip": ctx.req.headers["x-real-ip"] || "",
            "x-forwarded-for": ctx.req.headers["x-forwarded-for"] || "",
          },
        },
      });
      if (userLocation.data.userLocation) {
        loc = userLocation.data.userLocation;
      } else {
        if (userLocation.error) {
          loc = userLocation.error.toString();
        }
        if (userLocation.errors) {
          loc = userLocation.errors.toString();
        }
      }
    } catch {
      loc = "CATCH";
    }

    // loc = JSON.stringify(ctx.req.headers);

    return {
      props: {
        session: ctx.session,
        ipLocation: loc || "ERR",
      },
    };
  },
  { redirectOnlyUnconfirmed: true },
);

export default Home;
