import React, { useEffect } from "react";

import { Grid, Hidden, Skeleton } from "@mui/material";

import { useTranslation } from "react-i18next";

import Heading from "@/common/components/Heading";
import Link from "@/common/components/Link";
import MainBox from "@/common/components/MainBox";
import { PersonCardSkeleton } from "@/common/components/PersonCardSkeleton/PersonCardSkeleton";
import PeopleDesktop from "@/discover/components/PeopleDesktop";
import PeopleMobile from "@/discover/components/PeopleMobile";
import TourStep from "@/tour/components/TourStep";

import IconChevronLeft from "@/common/icons/icons-chevron-left";

import { useDiscoverStore } from "@/discover/hooks";

import useStyles from "./People.styles";

export const People = (props: any) => {
  const { t } = useTranslation();
  const { classes } = useStyles(props);
  const { people, loading, fetchPeople } = useDiscoverStore();

  useEffect(() => {
    fetchPeople({ limit: 24 });
  }, []);

  return (
    <>
      <Heading classes={{ box: classes.peopleTitleBox }}>
        <div className={classes.headingContainer}>
          <div className={classes.headingTitle}>
            <TourStep
              topic="discover"
              step={2}
              render={(props) => (
                <span {...props}>
                  {loading || people.length === 0 ? (
                    <Skeleton variant="text" animation="wave" width="15%" height={35}></Skeleton>
                  ) : (
                    <>
                      <div>{t("Home.DiscoverPeople")}</div>
                    </>
                  )}
                </span>
              )}
            />
          </div>
          <div className={classes.headingLinkContainer}>
            <Link href="/discover-people">
              <a className={classes.headingLink}>
                {loading || people.length === 0 ? (
                  <Skeleton variant="text" animation="wave" width="85px" height={25}></Skeleton>
                ) : (
                  <>
                    {t("Common.PeopleGrid.SeeAll")} <IconChevronLeft />
                  </>
                )}
              </a>
            </Link>
          </div>
        </div>
      </Heading>
      <Hidden smDown>
        <MainBox className={classes.mainBoxSmall}>
          {loading || people.length === 0 ? (
            <Grid container className={classes.gridSkeleton} spacing={1} wrap="nowrap">
              {Array(15)
                .fill(null)
                .map((_, i) => {
                  return (
                    <Grid className={classes.gridSkeletonItem} item key={i}>
                      <PersonCardSkeleton />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            <PeopleDesktop people={people} />
          )}
        </MainBox>
      </Hidden>
      <Hidden smUp>
        <MainBox className={classes.mainBox}>
          {true || people.length === 0 ? (
            <Grid container spacing={2} wrap="nowrap">
              {Array(6)
                .fill({})
                .map((_, i) => {
                  return (
                    <Grid item sm={2} key={i}>
                      <PersonCardSkeleton />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            <PeopleMobile people={people} loading={loading} />
          )}
        </MainBox>
      </Hidden>
    </>
  );
};
