import React, { useEffect, useState } from "react";

import { Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import ActivityFilterHeader from "@/common/components/ActivityFilterHeader/ActivityFilterHeader";
import { MainLayout } from "@/common/components/MainLayout/MainLayout";
import SEO from "@/common/components/SEO";
import { SuggestedActivities } from "@/common/components/SuggestedActivities/SuggestedActivities";
import Timeline from "@/community/components/Timeline";
import { HomeTabs } from "@/home/components/Tabs";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useCommunityTimeline } from "@/community/hooks";

import VerificationBanner from "@/account-verification/VerificationBanner";
import { CommunityTimelineItemType } from "@/graphql/types";
import SubscriptionBanner from "@/monetization/SubscriptionBanner";
const useStyles = makeStyles()((theme: Theme) => {
  return {
    spinnerContainer: {
      display: "flex",
      justifyContent: "center",
      padding: "4rem 0",
    },
    wrapper: {
      display: "flex",
      gap: theme.spacing(3),
      [theme.breakpoints.down("lg")]: {
        // gridTemplateColumns: "minmax(0, 1fr)",
        gap: "0",
      },
      [theme.breakpoints.down("md")]: {
        // padding: "0.5em 1.5rem 1.5rem 1.5rem",
        marginTop: "0",
      },
    },
    mainContent: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      width: "calc(100% - 400px)",
      [theme.breakpoints.down("lg")]: {
        gridRowStart: 2,
      },
    },
  };
});
const randomNumber = Math.random() * 1;
const HomePage = () => {
  const isViewportMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { classes } = useStyles();
  const { session } = useAuthenticationStore();
  const { hasMorePages, items, fetchItems, fetchNextItemsPage, loading } = useCommunityTimeline();
  const [timelineFilter, setTimelineFilter] = useState<CommunityTimelineItemType | null>(null);
  const [pendingScrollToTop, setPendingScrollToTop] = useState<boolean>(false);

  useEffect(() => {
    setPendingScrollToTop(true);
    fetchItems({ limit: 10, filter: timelineFilter });
  }, [timelineFilter]);

  useEffect(() => {
    if (!loading && pendingScrollToTop) {
      window.scrollTo({ top: 0 });
      setPendingScrollToTop(false);
    }
  }, [loading]);

  const [showVerificationBanner, setShowVerificationBanner] = React.useState<boolean>(
    (!session?.user?.verified && session?.user?.showVerificationBanner) || false,
  );
  const [showSubscriptionBanner, setShowSubscriptionBanner] = React.useState<boolean>(
    !session?.user?.activeSubscription,
  );

  const bothBannersActive = showVerificationBanner && showSubscriptionBanner;

  return (
    <MainLayout
      actionHeader={
        <ActivityFilterHeader onCommunityTimelineFilterChange={(type) => setTimelineFilter(type)} />
      }
      tabs={isMobile ? null : <HomeTabs />}>
      <SEO />
      {((!bothBannersActive && showVerificationBanner) ||
        (bothBannersActive && randomNumber < 0.5)) && (
        <VerificationBanner
          variant="discover"
          onDismissBanner={() => {
            setShowVerificationBanner(false);
            setShowSubscriptionBanner(false);
          }}
        />
      )}

      {((!bothBannersActive && showSubscriptionBanner) ||
        (bothBannersActive && randomNumber > 0.5)) && (
        <SubscriptionBanner
          variant="discover"
          onDismissBanner={() => {
            setShowSubscriptionBanner(false);
            setShowVerificationBanner(false);
          }}
        />
      )}
      <div className={classes.wrapper}>
        <div className={classes.mainContent}>
          <Timeline
            items={items}
            loading={loading}
            hasMorePages={hasMorePages}
            fetchNextItemsPage={fetchNextItemsPage}
          />
        </div>
        {!isViewportMd && <SuggestedActivities />}
      </div>
    </MainLayout>
  );
};

export default HomePage;
