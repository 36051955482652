import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  paper: {
    height: 234,
    width: 168,
    padding: theme.spacing(2.5, 1.25),
    margin: theme.spacing(0, 0.625),
    boxSizing: "border-box",
    borderRadius: 24,
    boxShadow: "0 0px 16px 0 rgba(63, 61, 86, 0.06)",
    [theme.breakpoints.down("md")]: {
      height: 200,
      width: 160,
      margin: 0,
      padding: theme.spacing(4.5, 1.25, 2.5, 1.25),
      boxShadow: "0 0px 16px 0 rgba(63, 61, 86, 0.04)",
    },
    backgroundColor: theme.mode.background.light,
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: -10,
    },
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
    "&:nth-child(1)": { marginTop: 15 },
    "&:nth-child(2)": { marginTop: 10 },
    "&:nth-child(3)": { marginTop: 5 },
    "&:nth-child(4)": { marginTop: 10 },
    [theme.breakpoints.down("md")]: {
      "& .cta": {
        marginTop: -7,
      },
      "& .interests": {
        marginTop: -1,
      },
      "& .contacts": {
        marginTop: -5,
      },
    },
  },
  personCardGrid: {
    width: 176,
    [theme.breakpoints.down("md")]: {
      width: 160,
    },
  },
}));
