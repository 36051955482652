import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
  },
  contentContainer: {
    paddingTop: 80,
    height: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: 77,
      paddingBottom: 112,
      overflowX: "hidden",
    },
  },
  hiddenTopBar: {
    paddingTop: 0,
  },
  innerContent: {
    flexGrow: 1,
  },
  mainContainer: {
    marginLeft: 282,
    minHeight: "calc(var(--vh, 1vh) * 100 - 80px + 40px)",
    padding: theme.spacing(1, 3, 3, 3),
    display: "flex",
    flexFlow: "column",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 0, 0),
    },
  },
  mainContainerWithSidebar: {
    marginLeft: 282,
    minHeight: "calc(var(--vh, 1vh) * 100 - 80px + 40px)",
    padding: theme.spacing(0, 3, 3, 3),
    display: "flex",
    flexFlow: "row",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      marginLeft: 0,
      flexFlow: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 0, 2),
    },
  },
  chatContainer: {
    minHeight: "calc(var(--vh, 1vh) * 100 - 80px)",
    padding: theme.spacing(1, 3, 0, 3),
  },
  mainContainerCenter: {
    marginRight: "24px",
    flexGrow: "1",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  sidebar: {
    width: "370px",
    flexShrink: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  stickyHeader: {
    background: "white",
    padding: theme.spacing(2),
  },
  mainContainerSidebar: {
    width: 370,
  },
}));
