import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ variant: "discover" | "profile" | "settings" }>()(
  (theme: Theme, props) => ({
    verificationBanner: {
      height: "72px",
      backgroundColor: theme.mode.background.light,
      display: "flex",
      alignItems: "center",
      padding: "0 20px",
      marginRight: props.variant === "discover" ? "-23px" : "0px",
      marginLeft: props.variant === "discover" ? "-19px" : "0px",
      marginTop: props.variant === "discover" ? "-4px" : "4px",

      "& .left": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "start",
        marginLeft: "8px",

        "& .heroMessage": {
          fontSize: "0.9375rem",
          color: theme.mode.text.heading,
          fontWeight: "bold",
        },
        "& .subtitle": {
          fontSize: "0.8125rem",
          color: theme.mode.text.description,
          fontWeight: "600",

          "& a": {
            fontSize: "0.8125rem",
            color: theme.palette.primary.main,
            fontWeight: "bold",
          },
        },
      },
      "& .right": {
        display: "flex",
        gap: "6px",
        "& .get-verified-btn": {
          minWidth: "160px",
          height: 40,
          justifyContent: "center",
          fontSize: "0.9375rem",
        },
      },

      [theme.breakpoints.down("sm")]: {
        padding: "0 18px",
        cursor: "pointer",
        marginRight: props.variant === "profile" ? "-23px" : "initial",
        marginLeft: props.variant === "profile" ? "-19px" : "initial",
      },
    },
    avatarWrapper: {
      width: "112px",
      height: "112px",
    },
    dialog: {
      borderRadius: 24,
      width: "612px",
      minHeight: "678px",
    },
    header: {
      padding: "32px 24px",
      borderBottom: `2px solid ${theme.mode.background.comment}`,
      [theme.breakpoints.down("sm")]: {
        padding: "16px",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: theme.mode.text.description,
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        // display: "none",
      },
    },
    titleLabel: {
      fontSize: "1.0625rem",
      fontWeight: "800",
      margin: "auto",
      color: theme.mode.text.heading,
    },
    closeButton: {
      marginRight: "-12px",
    },
    subheader: {
      fontSize: "0.9375rem",
      color: theme.mode.text.formLabel,
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "0.8125rem",
      },
    },
    body: {
      padding: "0",
      "& #sns-websdk-root": {
        backgroundColor: "blue !important",
      },
      "& .profile-picture": {
        paddingTop: "32px",
        textAlign: "center",

        "& .name": {
          paddingTop: "12px",
          "& .container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",

            "& svg": {
              height: "20px",
              width: "20px",
            },
            "& .displayName": {
              fontSize: "1.0625rem",
              fontWeight: "800",
              color: theme.mode.text.heading,
              lineHeight: "1.29",
            },
          },
        },
      },
      "& .bullet-point": {
        padding: "0px 32px",
        "& .header": {
          display: "flex",
          alignItems: "center",
          gap: "4px",
          marginTop: "29px",

          "& svg": {
            height: "21px",
            width: "21px",
            color: theme.palette.primary.main,
          },
          "& .title": {
            fontSize: "0.9375rem",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            lineHeight: "1.47",
          },
        },
        "& .text": {
          paddingTop: "13px",
          color: theme.mode.text.description,
          fontSize: "0.9375rem",
          lineHeight: "1.47",
        },
      },

      "& .actions": {
        display: "flex",
        marginTop: "40px",
        padding: "0px 32px",
        marginBottom: "35px",
        gap: "36px",

        justifyContent: "flex-end",
        "& .verify-account": {
          fontSize: "0.9375rem",
          fontWeight: "800",
          justifyContent: "center",
        },
        "& .cancel": {
          color: theme.mode.icon.light,
          fontWeight: "800",
          fontSize: "1.0625rem",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column-reverse",
        },
      },

      [theme.breakpoints.down("sm")]: {
        padding: "0px",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      [theme.breakpoints.down("sm")]: {
        gap: 0,
      },
    },
    formField: {
      "& label": {
        fontWeight: "400 !important",
        fontSize: "0.9375rem",
        [theme.breakpoints.down("sm")]: {
          fontWeight: "bold !important",
        },
      },
      "& input": {
        "&::placeholder": {
          fontSize: "0.8125rem",
          color: theme.mode.text.subtitle,
        },
        color: theme.mode.text.heading,
        fontSize: "0.8125rem",
      },
    },
    formBottom: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        alignSelf: "flex-start",
      },
    },
    signButton: {
      minWidth: "160px",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: 32,
        marginBottom: 48,
        width: "100%",
        fontSize: "0.9375rem",
      },
    },
    signAnonymouslyLabel: {
      marginLeft: "12px",
      fontSize: "1.0625rem",
      fontWeight: "600",
      color: theme.mode.text.formLabel,
      [theme.breakpoints.down("sm")]: {
        fontWeight: "bold !important",
        fontSize: "0.8125rem",
      },
    },
    headerCloseButton: {
      height: "auto",
      color: theme.mode.icon.default,
      position: "absolute",
      right: "15px",
    },
    bannerCloseButton: {
      height: "auto",
      color: "#bcbcbc",
    },
    checkbox: {
      padding: 0,
      "& > *": {
        height: "auto",
      },
    },
  }),
);

export default useStyles;
