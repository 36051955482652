import { makeStyles } from "tss-react/mui";

export const useSectionStyles = makeStyles()((theme) => ({
  title: {
    fontSize: "1.0625rem",
    fontWeight: 800,
    margin: 0,
    lineHeight: "normal",
    color: theme.mode.text.heading,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.065rem",
    },
  },
  section: {
    "&:not(:last-of-type)": {
      marginBottom: theme.spacing(4),
      borderBottom: "2px solid " + theme.mode.icon.backgroundSecondary,
      paddingBottom: 24,
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(3, 0),
  },

  mobileHeaderPadding: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "16px",
      marginRight: "16px",
    },
  },
  subsection: {
    border: "none",
  },
}));
