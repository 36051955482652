import { useAuthenticationStore } from "@/authentication/hooks";

import { CommunityTimelineItemType, useGetCommunityTimelineItemsLazyQuery } from "@/graphql/types";

export const useCommunityTimeline = () => {
  const { session } = useAuthenticationStore();

  const [fetchItems, { loading, data, error, fetchMore: fetchNextItemsPage }] =
    useGetCommunityTimelineItemsLazyQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    });

  return {
    items: data?.communityTimelineItems.edges.map(({ node }) => node) || [],
    loading,
    error: !!error,
    fetchItems({ limit, filter }: { limit: number; filter?: CommunityTimelineItemType | null }) {
      if (session && session.user) {
        return fetchItems({
          variables: {
            limit,
            filter: filter || undefined,
          },
        });
      }
    },
    fetchNextItemsPage() {
      if (fetchNextItemsPage && data) {
        return fetchNextItemsPage({
          variables: {
            after: data.communityTimelineItems.pageInfo.endCursor,
            limit: 20,
          },
        });
      }
    },
    hasMorePages: data?.communityTimelineItems.pageInfo.hasNextPage || false,
  };
};
