import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme: Theme) =>
    ({
      root: {
        position: "relative",
      },
      indicators: {
        width: "100%",
        marginTop: "10px",
        textAlign: "center",
      },
      indicator: {
        fontSize: "15px",
        cursor: "pointer",
        transition: "200ms",
        color: "#afafaf",
        "&:hover": {
          color: "#1f1f1f",
        },
        "&:active": {
          color: "#1f1f1f",
        },
      },
      active: {
        color: "#494949",
      },
      buttonWrapper: {
        position: "absolute",
        //theme.palette.common.white,
        height: 50,
        top: "calc(50% - 70px)",
        width: 75,
        "&.nextGradient": {
          background: theme.mode.carousel.next,
        },
        "&.previousGradient": {
          background: theme.mode.carousel.previous,
        },

        // '&:hover': {
        //   '& $button': {
        //     backgroundColor: "black",
        //     filter: "brightness(120%)",
        //     opacity: 0.4
        //   }
        // }
        // '&:before': {
        //   background: 'linear-gradient(transparent 150px, white)'
        // }
      },
      fullHeightHoverWrapper: {
        height: "calc(98%) !important",
        top: "0px !important",
      },
      button: {
        margin: "0 10px",
        position: "relative",
        //backgroundColor: "#494949",
        top: "30px",
        color: "black",
        transition: "200ms",
        cursor: "pointer",
        variant: "text",
        // '&:hover': {
        //   opacity: "0.6 !important"
        // }
      },
      fullHeightHoverButton: {
        top: "calc(50% - 20px) !important",
      },
      buttonHidden: {
        display: "none",
      },
      next: {
        right: -5,
        // background: 'linear-gradient(to right, rgba(255,255,255,0), white)',
        textAlign: "right",
        pointerEvents: "none",
      },
      nextIcon: {
        width: 35,
        height: 35,
        right: -15,
        pointerEvents: "all",
        boxShadow: "0 2px 16px -5px rgba(63,61,86,0.12)",
        backgroundColor: "#fff",
        "& .MuiSvgIcon-root": {
          color: "#3F3D56",
        },
        "&:hover": {
          backgroundColor: "#F0F0F0 !important",
          opacity: "1 !important",
        },
      },
      prevIcon: {
        width: 35,
        height: 35,
        left: -12,
        pointerEvents: "all",
        boxShadow: "0 2px 16px -5px rgba(63,61,86,0.12)",
        backgroundColor: "#fff",
        "& .MuiSvgIcon-root": {
          color: "#3F3D56",
        },
        "&:hover": {
          backgroundColor: "#F0F0F0 !important",
          opacity: "1 !important",
        },
      },
      prev: {
        left: -13,
        // background: 'linear-gradient(to left, rgba(255,255,255,0), white)',
        textAlign: "left",
        pointerEvents: "none",
      },
      arrowIcon: {
        fontSize: 24,
      },
    } as const),
);

export default useStyles;
