import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme: Theme) =>
    ({
      root: {
        flexGrow: 1,
        overflow: "hidden",
        padding: "10px 5px",
        paddingLeft: 3,

        "& .personCard:first-child": {
          marginLeft: 0,
          paddingLeft: 0,
        },
        [theme.breakpoints.up("md")]: {
          "& .personCard": {
            marginLeft: 0,
            paddingLeft: 0,
          },
        },
      },
      card: {
        textAlign: "center",
      },
      avatar: {
        display: "inline-flex",
        width: 66,
        height: 66,
        cursor: "pointer",
      },
      personCard: {
        height: 208,
        width: 187,
        borderRadius: 16,
        boxShadow: "0 0px 20px -8px rgb(190 190 197)",
        [theme.breakpoints.down("md")]: {
          width: "140px",
        },
      },
      primaryText: {
        fontWeight: 800,
        color: "#3f3d56",
        marginTop: 5,
      },
      secondaryText: {
        color: "#6c6b78",
        fontSize: 13,
        [theme.breakpoints.down("md")]: {
          fontSize: 11,
        },
      },
      followText: {
        fontWeight: 800,
        fontSize: 16,
        marginTop: 7,
        cursor: "pointer",
        display: "inline-block",
        padding: "3px 9px",
        borderRadius: "9px",
        transition: "0.2s",
        "&:hover": {
          backgroundColor: "#F7F7F7",
          color: theme.palette.primary.main,
        },
      },
      followTextSelected: {
        color: "#3f3d56",
      },
      controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      cardContent: {
        paddingLeft: 12,
        paddingRight: 12,
        textAlign: "center",
      },
    } as const),
);

export default useStyles;
