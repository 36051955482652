import React, { useEffect } from "react";

import { Link, Grid, useMediaQuery, Theme } from "@mui/material";

import { useTranslation } from "react-i18next";

import ActivityCard from "@/common/components/ActivityCard";
import Hide from "@/common/components/Hide";
import { Section } from "@/common/components/Section";
import SkeletonContentCard from "@/common/components/SkeletonContentCard";

import IconChevronLeft from "@/common/icons/icons-chevron-left";

import { useDiscoverStore } from "@/discover/hooks";

import { ExtendedActivityType } from "@/graphql/types";

import { useSidebarStyles } from "./Sidebar.styles";

type SuggestedActivitySectionProps = {
  title: string;
  activityType: ExtendedActivityType;
  numberOfActivities?: number;
};

const SuggestedActivitySection = ({
  title,
  activityType,
  numberOfActivities = 3,
}: SuggestedActivitySectionProps) => {
  const { t } = useTranslation();
  const { classes } = useSidebarStyles();
  const isViewportMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const { hasMorePages, activities, fetchActivities, fetchNextActivitiesPage, loading } =
    useDiscoverStore();

  useEffect(() => {
    fetchActivities({ limit: numberOfActivities, types: [activityType] });
  }, []);

  const SeeMoreAction = () => {
    const getActionLink = () => {
      switch (activityType) {
        case ExtendedActivityType.Group:
          return "/groups";
        case ExtendedActivityType.Debate:
          return "/debates";
        case ExtendedActivityType.Event:
          return "/events";
        case ExtendedActivityType.Blog:
          return "/blogs";
        case ExtendedActivityType.Petition:
          return "/petitions";
        default:
          return "/";
      }
    };

    return (
      <div className={classes.headingLinkContainer}>
        <Link href={getActionLink()} className={classes.headingLink}>
          {t("Common.PeopleGrid.SeeAll")} <IconChevronLeft />
        </Link>
      </div>
    );
  };

  return (
    <Hide if={!loading && activities.length < 1}>
      <Section
        withMobileHeaderPadding
        sectionTitle={title}
        headerActions={isViewportMd && <SeeMoreAction />}>
        <Grid container mb={2} spacing={2} direction="column" alignItems="stretch">
          {loading && activities.length < 1
            ? new Array(3).fill(null).map((_, index) => (
                <Grid item key={`${activityType}_${index}`} classes={{ root: classes.cardWrapper }}>
                  <SkeletonContentCard key={index} size="small" />
                </Grid>
              ))
            : activities.map((activity, index) => (
                <Grid item key={`${activityType}_${index}`} classes={{ root: classes.cardWrapper }}>
                  <ActivityCard
                    activity={activity}
                    showAuthor
                    cardType="vertical"
                    size="small"
                    hideBelonging
                    hideHeaderIcon
                  />
                </Grid>
              ))}
          {hasMorePages && !isViewportMd && (
            <Grid item className={classes.sidebarSectionFooter}>
              <Link component="button" underline="none" onClick={fetchNextActivitiesPage}>
                <span className={classes.seeMoreButton}>{t("Common.SeeMore")}</span>
              </Link>
            </Grid>
          )}
        </Grid>
      </Section>
    </Hide>
  );
};

export default SuggestedActivitySection;
