import React, { Component } from "react";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Fade, Slide, IconButton } from "@mui/material";

import autoBind from "auto-bind";

import useStyles from "./Carousel.styles";

class CarouselClass extends Component<any, any> {
  timer: any;

  constructor(props: any) {
    super(props);

    const strictIndexing = this.props.strictIndexing !== undefined ? props.strictIndexing : true;
    let startAt = this.props.startAt !== undefined ? props.startAt : 0;
    // if startAt is bigger than the children length, set it to be the last child (if strictIndexing)
    startAt = Array.isArray(this.props.children)
      ? strictIndexing && startAt > this.props.children.length - 1
        ? this.props.children.length - 1
        : startAt
      : 0;

    this.state = {
      active: startAt,
      autoPlay: this.props.autoPlay !== undefined ? this.props.autoPlay : true,
      interval: this.props.interval !== undefined ? this.props.interval : 4000,
      displayed: startAt,
    };

    this.timer = null;

    autoBind(this);
  }

  componentDidMount() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.autoPlay !== prevState.autoPlay || nextProps.interval !== prevState.interval) {
      return {
        autoPlay: nextProps.autoPlay !== undefined ? nextProps.autoPlay : true,
        interval: nextProps.interval !== undefined ? nextProps.interval : 4000,
      };
    } else return null;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.autoPlay !== prevState.autoPlay || prevProps.interval !== prevState.interval) {
      this.reset();
    }
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  start() {
    if (this.state.autoPlay) {
      this.timer = setInterval(this.next, this.state.interval);
    }
  }

  reset() {
    this.stop();

    if (this.state.autoPlay) {
      this.start();
    }
  }

  pressIndicator(index: any) {
    const animation = this.props.animation !== undefined ? this.props.animation : "fade";
    const timeout =
      this.props.timeout !== undefined ? this.props.timeout : animation === "fade" ? 500 : 200;

    this.setState(
      {
        active: index,
        displayed: this.state.active,
      },
      this.reset,
    );

    setTimeout(() => {
      this.setState({
        displayed: index,
      });
    }, timeout);
  }

  showsPrevArrow = () => {
    return this.state.active > 0;
  };

  showsNextArrow = () => {
    return this.state.active < (this.props.children as any).length - 1;
  };

  next(event: any) {
    const next =
      this.state.active + 1 > (this.props.children as any).length - 1 ? 0 : this.state.active + 1;
    const animation = this.props.animation !== undefined ? this.props.animation : "fade";
    const timeout =
      this.props.timeout !== undefined ? this.props.timeout : animation === "fade" ? 500 : 200;

    this.props.indicatorPressed(next);

    this.setState(
      {
        active: next,
        displayed: this.state.active,
      },
      this.reset,
    );

    setTimeout(() => {
      this.setState({
        displayed: next,
      });
    }, timeout);

    if (event) event.stopPropagation();
  }

  prev(event: any) {
    const prev =
      this.state.active - 1 < 0 ? (this.props.children as any).length - 1 : this.state.active - 1;
    const animation = this.props.animation !== undefined ? this.props.animation : "fade";
    const timeout =
      this.props.timeout !== undefined ? this.props.timeout : animation === "fade" ? 500 : 200;

    this.props.indicatorPressed(prev);

    this.setState(
      {
        active: prev,
        displayed: this.state.active,
      },
      this.reset,
    );

    setTimeout(() => {
      this.setState({
        displayed: prev,
      });
    }, timeout);

    if (event) event.stopPropagation();
  }

  render() {
    const indicators = this.props.indicators !== undefined ? this.props.indicators : true;
    const animation = this.props.animation !== undefined ? this.props.animation : "fade";
    const timeout =
      this.props.timeout !== undefined ? this.props.timeout : animation === "fade" ? 500 : 200;

    const { classes, cx } = this.props.classes;
    const buttonCssClassValue = `${classes.button} ${classes.fullHeightHoverButton}`;
    const buttonWrapperCssClassValue = `${classes.buttonWrapper} ${classes.fullHeightHoverWrapper}`;

    return (
      <div
        className={`${classes.root} ${this.props.className ? this.props.className : ""}`}
        onMouseEnter={this.stop}
        onMouseOut={this.reset}>
        {Array.isArray(this.props.children) ? (
          this.props.children.map((child, index) => {
            return (
              <CarouselItem
                key={`carousel-item${index}`}
                display={index === this.state.displayed ? true : false}
                active={index === this.state.active ? true : false}
                child={child}
                animation={animation}
                timeout={timeout}
              />
            );
          })
        ) : (
          <CarouselItem
            key={`carousel-item0`}
            display={true}
            active={true}
            child={this.props.children}
            animation={animation}
            timeout={timeout}
          />
        )}

        {!!this.showsNextArrow() ? (
          <div className={`${buttonWrapperCssClassValue} ${classes.next} nextGradient`}>
            <IconButton
              className={cx(buttonCssClassValue, classes.nextIcon)}
              onClick={this.next}
              size="large">
              <ArrowForwardRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
        ) : null}

        {!!this.showsPrevArrow() ? (
          <div className={`${buttonWrapperCssClassValue} ${classes.prev} previousGradient`}>
            <IconButton
              className={cx(buttonCssClassValue, classes.prevIcon)}
              onClick={this.prev}
              size="large">
              <ArrowBackRoundedIcon className={classes.arrowIcon} />
            </IconButton>
          </div>
        ) : null}

        {indicators ? (
          <Indicators
            classes={classes}
            length={(this.props.children as any).length}
            active={this.state.active}
            press={this.pressIndicator}
          />
        ) : null}
      </div>
    );
  }
}

function CarouselItem(props: any) {
  return props.display ? (
    <div className="CarouselItem">
      {props.animation === "slide" ? (
        <Slide direction="left" in={props.active} timeout={props.timeout}>
          <div>{props.child}</div>
        </Slide>
      ) : (
        <Fade in={props.active} timeout={props.timeout}>
          <div>{props.child}</div>
        </Fade>
      )}
    </div>
  ) : null;
}

function Indicators(props: any) {
  const classes = props.classes;

  const indicators = [];
  for (let i = 0; i < props.length; i++) {
    const className =
      i === props.active ? `${classes.indicator} ${classes.active}` : `${classes.indicator}`;
    const item = (
      <FiberManualRecordIcon
        key={i}
        // size="small"
        className={className}
        onClick={() => {
          props.press(i);
        }}
      />
    );

    indicators.push(item);
  }

  return <div className={`${classes.indicators}`}>{indicators}</div>;
}

const Carousel = (props: any) => {
  const classes = useStyles();

  return <CarouselClass {...props} classes={classes} />;
};

export default Carousel;
