import React, { useState } from "react";

import { Card, CardContent, Tooltip, Typography, Badge } from "@mui/material";

import { useTranslation } from "react-i18next";

import FollowText from "@/common/components/FollowText";
import Link from "@/common/components/Link";
import Snackbar from "@/common/components/Snackbar";

import VerifiedIcon from "@/common/icons/VerifiedIcon";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useProfileFollowersStore } from "@/profile/hooks";

import { FollowUserMutation, UserFragment, UserCardFragment } from "@/graphql/types";

import { getFullAuthorName } from "../../services/fullName";
import UserAvatar from "../UserAvatar";
import useStyles from "./PersonCard.styles";

type PersonCardProps = {
  person: UserFragment | UserCardFragment;
  hideCommonInterests?: boolean;
  hideCommonContacts?: boolean;
  inPeopleGrid?: boolean;
};

export const PersonCard = (props: PersonCardProps) => {
  const { person, inPeopleGrid } = props;
  const { session } = useAuthenticationStore();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { loadingToggleFollow, toggleFollow, undoFollowRequest } = useProfileFollowersStore({
    user: person,
  });
  const { t } = useTranslation();
  const { classes, cx } = useStyles({});

  const commonFriendsCount = (person: UserFragment | UserCardFragment) => {
    return person.commonFollows?.count;
  };

  const commonInterestsCount = (person: UserFragment | UserCardFragment) => {
    return person.commonInterests?.count;
  };

  const handleFollowActionClick = () => {
    if (person.follows.hasBeenRequestedByMe) {
      undoFollowRequest();
    } else {
      if (person.follows.isFollowedByMe) {
        toggleFollow();
      } else {
        toggleFollow()?.then((response) => {
          const result = response.data as FollowUserMutation;
          if (
            !result.followUser?.successful &&
            session?.user?.follows.followingCount &&
            session?.user?.follows.followingCount > 4999
          ) {
            setSnackbarOpen(true);
          }
        });
      }
    }
  };

  const commonFriendsText = (person: UserFragment | UserCardFragment) => {
    const friendsCount = commonFriendsCount(person);
    if (person.isMe) {
      return friendsCount + " " + t("PersonCard.Contacts");
    }
    if (friendsCount === 0) {
      return t("Generic.PersonCard.NoCommonContacts");
    } else if (friendsCount === 1) {
      return t("Generic.PersonCard.OneContactInCommon");
    }
    return friendsCount + " " + t("Generic.PersonCard.MultipleContactsInCommon");
  };

  const commonInterestsText = (person: UserFragment | UserCardFragment) => {
    const interestsCount = commonInterestsCount(person);
    if (person.isMe) {
      return interestsCount + " " + t("PersonCard.Interests");
    }
    if (interestsCount === 0) {
      return t("Generic.PersonCard.NoSimilarInterests");
    }
    return interestsCount + " " + t("Generic.PersonCard.MultipleSimilarInterests");
  };

  return (
    <>
      <Badge
        invisible={!person?.isNew}
        badgeContent={t("Common.new")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ badge: classes.newBadge }}>
        <Card
          className={cx(classes.personCard, {
            [classes.personCardGrid]: inPeopleGrid,
          })}
          data-cy="PersonCard">
          {!!props.person && !!props.person.id ? (
            <CardContent className={classes.cardContent}>
              <Link href={person.url}>
                <a>
                  <UserAvatar className={classes.avatar} user={person} />
                </a>
              </Link>

              <Link href={person.url}>
                <a>
                  {person && person.verified ? (
                    <div className={classes.flexContainer}>
                      <span className="display-left">
                        <Tooltip title={getFullAuthorName(person)} placement="right" arrow>
                          <Typography
                            variant="h5"
                            className={classes.primaryText}
                            gutterBottom
                            noWrap>
                            {getFullAuthorName(person)}
                          </Typography>
                        </Tooltip>
                      </span>
                      <span className="display-right">
                        <VerifiedIcon />
                      </span>
                    </div>
                  ) : (
                    <Tooltip title={getFullAuthorName(person)} placement="right" arrow>
                      <Typography variant="h5" className={classes.primaryText} gutterBottom noWrap>
                        {getFullAuthorName(person)}
                      </Typography>
                    </Tooltip>
                  )}
                </a>
              </Link>

              {!props.hideCommonInterests && (
                <Tooltip title={commonFriendsText(person)} placement="right" enterDelay={50} arrow>
                  <Typography className={classes.secondaryText}>
                    {commonFriendsText(person)}
                  </Typography>
                </Tooltip>
              )}
              {!props.hideCommonInterests && (
                <Tooltip
                  title={commonInterestsText(person)}
                  placement="right"
                  enterDelay={50}
                  arrow>
                  <Typography className={classes.secondaryText}>
                    {commonInterestsText(person)}
                  </Typography>
                </Tooltip>
              )}
              <FollowText
                person={props.person}
                disabled={loadingToggleFollow}
                onFollowClicked={handleFollowActionClick}
                classes={classes}
              />
            </CardContent>
          ) : null}
        </Card>
      </Badge>
      <Snackbar
        message={t("Generic.FollowingUsersLimitReached")}
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      />
    </>
  );
};
