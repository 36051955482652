import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import Link from "@/common/components/Link";

import { UserFullFragment } from "@/graphql/types";

import useStyles from "./PersonCard/PersonCard.styles";

function FollowText({
  person,
  disabled,
  onFollowClicked,
}: {
  classes?: Record<string, string>;
  person: Pick<UserFullFragment, "id" | "isMe" | "url"> & {
    follows: Pick<UserFullFragment["follows"], "isFollowedByMe" | "hasBeenRequestedByMe">;
  };
  disabled?: boolean;
  onFollowClicked: (personId: string, followState: boolean) => void;
}) {
  const [follow, setFollow] = useState(person.follows.isFollowedByMe);
  const { t } = useTranslation();
  const { classes, cx } = useStyles({});

  useEffect(() => {
    setFollow(person.follows.isFollowedByMe);
  }, [person]);

  const handleFollowClick = () => {
    if (person.id) {
      onFollowClicked(person.id, follow || false);
    }
  };

  const getLabel = () => {
    if (!disabled) {
      return person.follows.hasBeenRequestedByMe
        ? t("Generic.Requested")
        : person.follows.isFollowedByMe
        ? t("Discover.UnfollowText")
        : t("Discover.FollowText");
    } else {
      return person.follows.hasBeenRequestedByMe || person.follows.isFollowedByMe
        ? `${t("Generic.Unfollowing")}...`
        : `${t("Generic.Following")}...`;
    }
  };

  return !person.isMe ? (
    <Button variant="formless" disabled={disabled} onClick={handleFollowClick}>
      <Typography
        color="primary"
        className={cx(
          classes?.followText,
          person.follows.isFollowedByMe && classes?.followed,
          person.follows.hasBeenRequestedByMe && (classes?.requested || disabled),
        )}>
        {getLabel()}
      </Typography>
    </Button>
  ) : (
    <Button variant="formless" disabled={false}>
      <Link href={person.url}>
        <a>
          <Typography color="primary" className={cx(classes?.followText)}>
            {t("Chat.SeeProfile")}
          </Typography>
        </a>
      </Link>
    </Button>
  );
}

export default FollowText;
