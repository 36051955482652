import React from "react";

import {
  Skeleton,
  Card,
  CardContent,
  Grid,
  Hidden,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<
  { mediaSize: number },
  "mediaContainer" | "contentContainer" | "actionsContainer" | "middleSkeleton" | "bottomSkeleton"
>()((theme: Theme, { mediaSize }, classes) => {
  const smallCardStyles = {
    mediaContainer: {
      padding: theme.spacing(2.5, 0.75, 2.5, 2.5),
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    contentContainer: {
      padding: theme.spacing(2.5, 2.5, 2.5, 0.75),
      "&:last-child": {
        paddingBottom: theme.spacing(2.5),
      },
      [`& .${classes.middleSkeleton}`]: {
        height: "40px",
      },
      [`& .${classes.bottomSkeleton}`]: {
        height: "3em",
      },
    },
    actionsContainer: {
      paddingBottom: theme.spacing(1),
    },
  };
  return {
    verticalCard: {
      height: 407,
      paddingBottom: 0,
      borderRadius: "16px",
      backgroundColor: theme.mode.background.light,
      boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
    },
    card: {
      height: 196,
      minWidth: 265,
      paddingBottom: 0,
      [theme.breakpoints.down("sm")]: {
        height: 152,
      },
      backgroundColor: theme.mode.background.light,
    },
    small: {
      height: 152,
      [`& .${classes.mediaContainer}`]: { ...smallCardStyles.mediaContainer },
      [`& .${classes.contentContainer}`]: { ...smallCardStyles.contentContainer },
      [`& .${classes.actionsContainer}`]: { ...smallCardStyles.actionsContainer },
    },
    normal: {},
    mediaContainer: {
      boxSizing: "border-box",
      padding: theme.spacing(3),
      paddingRight: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.mediaContainer,
      },
    },
    contentContainer: {
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.contentContainer,
      },
    },
    actionsContainer: {
      paddingTop: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        ...smallCardStyles.actionsContainer,
      },
    },
    rightContainer: {
      minWidth: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
    },
    topSkeleton: {},
    middleSkeleton: {
      width: "75%",
      height: "45px",
    },
    bottomSkeleton: {
      height: "5em",
    },
    media: { height: mediaSize, width: mediaSize, borderRadius: 24 },
    headerVertical: {
      padding: "14px 12px",
      paddingBottom: 6,
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    avatar: {
      "& span": {
        width: 40,
        height: 40,
      },
    },
    title: {
      flexGrow: "1",
    },
    descriptionVertical: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 19,
      paddingBottom: 5,
      "& span": {
        height: 30,
      },
    },
    mediaVertical: {
      marginTop: 3,
    },
    footerVertical: {
      display: "flex",
      height: "73px",
      alignItems: "center",
    },
    left: { flexGrow: 1.7, paddingLeft: 16 },
    right: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "end",
      "& span": {
        marginRight: 16,
      },
    },
    skeletonRoot: {
      backgroundColor: "#c9c9c94f",
    },
  };
});

interface SkeletonContentCardProps {
  size?: "small" | "normal";
  variant?: "long" | "vertical";
}

function SkeletonContentCard(props: SkeletonContentCardProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const mediaSize = isMobile || props.size === "small" ? 72 : 148;
  const { classes, cx } = useStyles({ mediaSize });

  return props.variant === "vertical" ? (
    <Card className={cx(classes.verticalCard, props.size && classes[props.size])} elevation={3}>
      <div className={classes.headerVertical}>
        <div className={classes.avatar}>
          <Skeleton animation="wave" classes={{ root: classes.skeletonRoot }} variant="circular" />
        </div>
        <div className={classes.title}>
          <Skeleton
            animation="wave"
            classes={{ root: classes.skeletonRoot }}
            style={{ width: "80%" }}
          />
          <Skeleton
            animation="wave"
            classes={{ root: classes.skeletonRoot }}
            style={{ width: "40%" }}
          />
        </div>
      </div>
      <div className={classes.descriptionVertical}>
        <Skeleton
          animation="wave"
          classes={{ root: classes.skeletonRoot }}
          style={{ width: "95%" }}
        />
      </div>
      <div className={classes.mediaVertical}>
        <Skeleton
          animation="wave"
          classes={{ root: classes.skeletonRoot }}
          variant="rectangular"
          style={{ height: "214px", borderRadius: 0 }}
        />
      </div>
      <div className={classes.footerVertical}>
        <div className={classes.left}>
          <Skeleton
            animation="wave"
            classes={{ root: classes.skeletonRoot }}
            style={{ width: "80%" }}
          />
        </div>
        <div className={classes.right}>
          <Skeleton
            animation="wave"
            classes={{ root: classes.skeletonRoot }}
            variant="rectangular"
            style={{ width: "120px", height: 40, borderRadius: 20 }}
          />
        </div>
      </div>
    </Card>
  ) : (
    <Card className={cx(classes.card, props.size && classes[props.size])} elevation={3}>
      <Grid container direction="column">
        <Grid item container wrap="nowrap">
          <Grid item>
            <div className={classes.mediaContainer}>
              <Skeleton animation="wave" variant="rectangular" className={classes.media} />
            </div>
          </Grid>
          <Grid item className={classes.rightContainer} xs>
            <CardContent classes={{ root: classes.contentContainer }}>
              <Typography variant="h4" width="35%">
                <Skeleton animation="wave" className={classes.topSkeleton} />
              </Typography>

              <Skeleton animation="wave" variant="text" className={classes.middleSkeleton} />

              <Hidden xsDown>
                <Skeleton animation="wave" className={classes.bottomSkeleton} />
              </Hidden>
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default SkeletonContentCard;
