import React, { HTMLAttributes, ReactNode } from "react";

import { useSectionStyles } from "./Section.styles";

interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  sectionTitle: string | ReactNode;
  headerActions?: ReactNode;
  withMobileHeaderPadding?: boolean;
  isSubsection?: boolean;
}
export const Section = ({
  children,
  sectionTitle,
  headerActions,
  withMobileHeaderPadding,
  isSubsection,
  ...delegated
}: SectionProps) => {
  const { cx, classes } = useSectionStyles();

  return (
    <section {...delegated} className={cx(classes.section, { [classes.subsection]: isSubsection })}>
      <header
        className={cx(classes.heading, {
          [classes.mobileHeaderPadding]: !!withMobileHeaderPadding,
        })}>
        <h1 className={classes.title}>{sectionTitle}</h1>
        {headerActions}
      </header>
      {children}
    </section>
  );
};
