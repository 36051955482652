import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { IconButton, Theme, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";

import CloseIcon from "@/common/icons/CloseIcon";
import CrownIcon from "@/common/icons/CrownIcon";

import { useAuthenticationStore } from "@/authentication/hooks";

import { parsedQueryParamStringOrArray } from "@/common/services";

import { useSubscriptionProvider } from "./providers/SubscriptionProvider";
import useStyles from "./SubscriptionBanner.styles";

type TProps = {
  variant: "discover" | "profile" | "settings";
  onDismissBanner: () => void;
};
const SubscriptionBanner = ({ variant, onDismissBanner }: TProps) => {
  const { classes } = useStyles({ variant });
  const { t } = useTranslation();
  const { session } = useAuthenticationStore();
  const router = useRouter();
  const subscriptionProvider = useSubscriptionProvider();
  const { payment_intent_client_secret, redirect_status, payment_type } = router.query;

  const [isPlanSelectorOpen, setIsPlanSelectorOpen] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const onBannerDismiss = async () => {
    onDismissBanner && onDismissBanner();
  };
  useEffect(() => {
    if (
      parsedQueryParamStringOrArray(payment_type) === "subscribe" &&
      payment_intent_client_secret &&
      redirect_status &&
      !isPlanSelectorOpen
    ) {
      setIsPlanSelectorOpen(true);
    }
  }, [payment_intent_client_secret]);

  return (
    <>
      {variant !== "settings" && (
        <>
          <div className={classes.verificationBanner}>
            <div onClick={() => subscriptionProvider.open()}>
              <CrownIcon usePrimary />
            </div>
            {isMobile ? (
              <>
                <div className="left" onClick={() => subscriptionProvider.open()}>
                  <div className="heroMessage">
                    {t("Monetization.Subscriptions.Banner.Title", {
                      username: session?.user?.displayName,
                    })}
                  </div>
                  <div className="subtitle">
                    {t("Monetization.Subscriptions.Banner.Description")}
                  </div>
                </div>
                <div className="right">
                  <IconButton
                    classes={{ root: classes.bannerCloseButton }}
                    onClick={onBannerDismiss}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div className="left">
                  <div className="heroMessage">
                    {t("Monetization.Subscriptions.Banner.Title", {
                      username: session?.user?.displayName,
                    })}
                  </div>
                  <div className="subtitle">
                    {t("Monetization.Subscriptions.Banner.Description")}&nbsp;
                    <span onClick={() => subscriptionProvider.open()}>
                      {t("Monetization.Subscriptions.Banner.CtaLearnMore")}
                    </span>
                  </div>
                </div>
                <div className="right">
                  <IconButton
                    classes={{ root: classes.bannerCloseButton }}
                    onClick={onBannerDismiss}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SubscriptionBanner;
