import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { palettes } from "@/common/constants/palettes";

export default makeStyles<{ badgeColor?: string }>()((theme: Theme, props) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: 10,
  },
  card: {
    textAlign: "center",
  },
  avatar: {
    display: "inline-flex",
    width: 72,
    height: 72,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 54,
      height: 54,
    },
  },
  personCard: {
    height: 234,
    width: 170,
    margin: theme.spacing(0, 0.625),
    [theme.breakpoints.down("md")]: {
      height: 204,
      width: 160,
      margin: 0,
    },
    backgroundColor: theme.mode.background.light,
    boxShadow: theme.mode.shadow.smallCard,
  },
  personCardGrid: {
    width: 176,
    [theme.breakpoints.down("md")]: {
      width: 160,
    },
  },
  primaryText: {
    fontWeight: "bold",
    color: theme.mode.text.heading,
    marginTop: 5,
    fontSize: "1.313rem",
    alignItems: "center",
    gap: 4,
    justifyContent: "center",
    overflow: "hidden",
    wordWrap: "break-word",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      marginBottom: 0,
    },
  },
  secondaryText: {
    color: theme.mode.text.description,
    fontSize: "0.93rem",
    display: "-webkit-box",
    overflow: "hidden",
    wordWrap: "break-word",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "1",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  },
  followText: {
    fontWeight: 800,
    fontSize: "1.065rem",
    marginTop: 7,
    cursor: "pointer",
    display: "inline-block",
    padding: "3px 9px",
    borderRadius: "9px",
    transition: "0.2s",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.mode.background.default,
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.93rem",
    },
  },
  followed: {
    color: theme.mode.text.heading,
  },
  requested: {
    color: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: "unset",
      color: theme.palette.grey[600],
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardContent: {
    paddingLeft: 12,
    paddingRight: 12,
    textAlign: "center",
    paddingTop: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.93rem",
      paddingTop: "32px",
    },
  },
  newBadge: {
    backgroundColor: palettes[props.badgeColor || ""]?.primary.main || theme.palette.primary.main,
    zIndex: 10,
    borderRadius: 10.5,
    textTransform: "uppercase",
    color: "white",
    fontSize: "0.5625rem",
    lineHeight: "1",
    fontWeight: "bold",
    height: 20,
    transform: "none",
    right: 16,
    top: 10,
    minWidth: 52,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.53rem",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",

    "& .display-left": {
      overflow: "hidden",
      wordWrap: "break-word",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: "1",
    },
    "& .display-right": {
      "& svg": {
        width: 14,
        height: 14,
        marginTop: 2,
      },
    },
  },
}));
