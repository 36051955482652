import { useRouter } from "next/router";
import React, { useState } from "react";

import { CircularProgress, Dialog, IconButton, Theme, useMediaQuery } from "@mui/material";

import snsWebSdk from "@sumsub/websdk";
import { useTranslation } from "react-i18next";

import { Button } from "@/common/components/button/Button";
import Link from "@/common/components/Link";
import UserAvatar from "@/common/components/UserAvatar";

import CloseIcon from "@/common/icons/CloseIcon";
import VerifiedIcon from "@/common/icons/VerifiedIcon";

import { useAuthenticationStore } from "@/authentication/hooks";

import { useUserVerificationStore } from "./hooks/use-user-verification-store";
import useStyles from "./VerificationBanner.styles";

type TProps = {
  variant: "discover" | "profile" | "settings";
  onDismissBanner: () => void;
};
const VerificationBanner = ({ variant, onDismissBanner }: TProps) => {
  const { classes } = useStyles({ variant });
  const { i18n, t } = useTranslation();
  const { verificationAccessToken, closeVerificationBanner, loadingCloseBanner } =
    useUserVerificationStore();
  const { session } = useAuthenticationStore();
  const [showIntro, setShowIntro] = React.useState(true);

  const [isVerificationOpen, setIsVerificationOpen] = useState(variant === "settings");

  const launchWebSdk = (accessToken: string) => {
    const snsWebSdkInstance = snsWebSdk
      .init(
        accessToken,
        // token update callback, must return Promise
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        () => getNewAccessToken(),
      )
      .withConf({
        lang: i18n.language || "en", //language of WebSDK texts and comments (ISO 639-1 format)
        email: session?.user?.email || "",
        phone: "",
        theme: "light",
        uiConf: {
          scrollIntoView: true,
        },
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      // see below what kind of messages WebSDK generates
      .on("idCheck.onStepCompleted", (payload) => {
        console.log("onStepCompleted", payload);
      })
      .on("idCheck.onError", (error) => {
        console.log("onError", error);
      })
      .on("idCheck.onApplicantStatusChanged", (payload) => {
        console.log("onApplicantStatusChanged", payload);
      })
      .build();

    snsWebSdkInstance.launch("#sumsub-websdk-container");
  };

  const getNewAccessToken = async (): Promise<string> => {
    return Promise.resolve<string>(verificationAccessToken);
  };

  const launchVerification = () => {
    setShowIntro(false);
    setTimeout(() => {
      launchWebSdk(verificationAccessToken);
    }, 200);
  };

  const bulletPoints = [
    {
      id: 1,
      icon: <VerifiedIcon usePrimary />,
      title: t("AccountVerification.BottomSheet.AVerifiedBadge.Title"),
      text: t("AccountVerification.BottomSheet.AVerifiedBadge.Description"),
    },
    {
      id: 2,
      icon: <VerifiedIcon usePrimary />,
      title: t("AccountVerification.BottomSheet.SafetyAndSecurity.Title"),
      text: t("AccountVerification.BottomSheet.SafetyAndSecurity.Description"),
    },
    {
      id: 3,
      icon: <VerifiedIcon usePrimary />,
      title: t("AccountVerification.BottomSheet.Support.Title"),
      text: t("AccountVerification.BottomSheet.Support.Description"),
    },
  ];

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const router = useRouter();

  const onDialogClose = () => {
    if (variant === "settings") {
      router.push("/settings/account/");
      return false;
    }

    setShowIntro(true);
    setIsVerificationOpen(false);
  };
  const onBannerDismiss = async () => {
    if (!loadingCloseBanner) {
      const result = await closeVerificationBanner();
      if (result.data?.closeVerificationBanner?.successful) {
        onDismissBanner && onDismissBanner();
      }
    }
  };

  return (
    <>
      {variant !== "settings" && (
        <>
          <div className={classes.verificationBanner}>
            <div onClick={() => setIsVerificationOpen(true)}>
              <VerifiedIcon />
            </div>
            {isMobile ? (
              <>
                <div className="left" onClick={() => setIsVerificationOpen(true)}>
                  <div className="heroMessage">
                    {t("AccountVerification.Banner.GetVerified", {
                      username: session?.user?.displayName,
                    })}
                  </div>
                  <div className="subtitle">{t("AccountVerification.Banner.Description")}</div>
                </div>
                <div className="right">
                  <IconButton
                    classes={{ root: classes.bannerCloseButton }}
                    onClick={onBannerDismiss}
                    size="large">
                    {loadingCloseBanner ? <CircularProgress size={20} /> : <CloseIcon />}
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div className="left">
                  <div className="heroMessage">
                    {t("AccountVerification.Banner.GetVerified", {
                      username: session?.user?.displayName,
                    })}
                  </div>
                  <div className="subtitle">
                    {t("AccountVerification.Banner.Description")}&nbsp;
                    <Link href={"/settings/verification/"}>
                      {t("AccountVerification.Banner.LearnMore")}
                    </Link>
                  </div>
                </div>
                <div className="right">
                  <Button className="get-verified-btn" onClick={() => setIsVerificationOpen(true)}>
                    {t("AccountVerification.Banner.GetVerifiedButton")}
                  </Button>
                  <IconButton
                    classes={{ root: classes.bannerCloseButton }}
                    onClick={onBannerDismiss}
                    size="large">
                    {loadingCloseBanner ? <CircularProgress size={20} /> : <CloseIcon />}
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Dialog
        open={isVerificationOpen}
        classes={{ paper: classes.dialog }}
        onClose={onDialogClose}
        data-cy="SignatoryNameDialog">
        <>
          <div className={classes.header}>
            <div className={classes.title}>
              <div className={classes.titleLabel}>
                {showIntro
                  ? t("AccountVerification.BottomSheet.Title")
                  : t("AccountVerification.BottomSheet.AccountVerificationTitle")}
              </div>
              <IconButton
                classes={{ root: classes.headerCloseButton }}
                onClick={onDialogClose}
                size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.body}>
            {showIntro ? (
              <>
                <div className="profile-picture">
                  <div className="picture">
                    <UserAvatar
                      user={session?.user}
                      className={classes.avatarWrapper}
                      hidePresence
                    />
                  </div>
                  <div className="name">
                    <div className="container">
                      <div className="displayName">{session?.user?.displayName}</div>
                      <VerifiedIcon />
                    </div>
                  </div>
                </div>
                {bulletPoints.map((point) => {
                  return (
                    <div key={`ver_bp_${point.id}`} className="bullet-point">
                      <div className="header">
                        {point.icon}
                        <div className="title">{point.title}</div>
                      </div>
                      <div className="text">{point.text}</div>
                    </div>
                  );
                })}
                <div className="actions">
                  <Button variant="text" className="cancel" onClick={onDialogClose}>
                    {t("Generic.Cancel")}
                  </Button>
                  <Button className="verify-account" onClick={launchVerification} uppercase>
                    {t("AccountVerification.BottomSheet.Button")}
                  </Button>
                </div>
              </>
            ) : (
              <div id="sumsub-websdk-container"></div>
            )}
          </div>
        </>
      </Dialog>
    </>
  );
};

export default VerificationBanner;
