import { UserCardFragment, UserFragment } from "@/graphql/types";

export class PeopleProvider {
  private REPEAT = 1;

  constructor(private people: UserFragment[] | UserCardFragment[]) {}

  public getGroupsOfPeople(size: number): { people: UserFragment[] | UserCardFragment[] }[] {
    const peopleWithRepeat: UserFragment[] | UserCardFragment[] = this.getPeopleWithRepeat(size);

    const groups: { people: UserFragment[] | UserCardFragment[] }[] = [];

    let index = 0;
    while (index < peopleWithRepeat.length) {
      const pagedPeople = peopleWithRepeat.slice(index, index + size);
      if (pagedPeople.length > 0) {
        groups.push({
          people: pagedPeople,
        });
      }
      index += size;
    }

    return groups;
  }

  private getPeopleWithRepeat(size: number): UserFragment[] | UserCardFragment[] {
    const result = this.people.slice(0, size);

    let index = size;
    const totalLength = this.people.length;
    while (index < totalLength) {
      result.push(...this.people.slice(index - this.REPEAT, index - this.REPEAT + size));
      index += size - this.REPEAT;
    }

    if (totalLength > 0 && totalLength % size === 0) {
      result.push(this.people[totalLength - 1]);
    }

    return result;
  }
}
