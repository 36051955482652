import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { PersonCard } from "@/common/components/PersonCard/PersonCard";

import { UserCardFragment, UserFragment } from "@/graphql/types";

import useStyles from "./PeopleMobile.styles";

interface Props {
  people: UserFragment[] | UserCardFragment[];
  loading?: boolean;
}

function PeopleMobile(props: Props) {
  const { classes } = useStyles();
  const [people, setPeople] = useState<UserFragment[] | UserCardFragment[]>([]);

  useEffect(() => {
    if (!props.people || props.people.length === 0) {
      return;
    }
    setPeople(props.people);
  }, [props]);

  function VisiblePeople(props: any) {
    const displayedPeople = props.people;

    const content = displayedPeople.map((person: any, index: any) => {
      return (
        <Grid item key={person.id}>
          <PersonCard key={index} person={person} />
        </Grid>
      );
    });

    return (
      <div className={classes.card}>
        <Grid container spacing={1} className={classes.root} wrap="nowrap">
          {content}
        </Grid>
      </div>
    );
  }

  return people.length > 0 ? <VisiblePeople people={people} /> : null;
}

export default PeopleMobile;
